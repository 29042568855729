/* eslint-disable import/no-anonymous-default-export */
// https://material-ui.com/customization/palette/
import defaultPalette from "../default/palette";
import circleELO from "../../../../src/components/Icons/elo-logo@3x.png";

export const palette = {
  ...defaultPalette.palette,
  brand: {
    primaryBg: "#ffca05",
    introMarqueeBg: "#ffffff",
    guideSelectBg: "#000000",
    text: "#000",
    map: "#00A7A7",
    icon: circleELO,
    footerBg: "#000000",
    bannerTilesBg: "#ffffff", //guide page
  },
};

export default {
  palette,
};
