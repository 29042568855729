import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { Box } from "@mui/material/";
import cx from "classnames";
import theme from "../../../util/theme/default";

const PREFIX = "Container";

const classes = {
  container: `${PREFIX}-container`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.container}`]: {
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
  },
}));

const Container = ({ className, isWrapper, ...props }) => {
  return (
    <StyledBox
      {...props}
      className={cx(classes.container, props.className)}
      maxWidth={isWrapper ? theme.containerMaxWidth + 56 : props.maxWidth}
      marginX={isWrapper ? "auto" : props.marginX}
    ></StyledBox>
  );
};

Container.defaultProps = {
  width: "100%",
  bgcolor: theme.palette.common.white,
  isWrapper: true, //wrapper is a display box element, will be true in most circumstances, exception header for an example
};

Container.propTypes = {
  className: PropTypes.string,
  isWrapper: PropTypes.bool,
  props: PropTypes.object,
};

export default Container;
