import React, { useContext, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import {
  Box,
  Drawer,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material/";
import { getMerchantContent } from "../../Map/mapmethods";
import { MapContext } from "../../../contexts";
import CATEGORIES from "../../../util/categories";
import Ratings from "../Ratings";
import CloseButton from "../../CloseButton";
import Skeleton from "@mui/material/Skeleton";
import AcceptanceMarks from "../../AcceptanceMarks";
import common from "../../../util/lang/common";
import t__ from "../../../util/lang/util";
import MerchantImage from "./MerchantImage";
import MerchantHours from "./MerchantHours";
import MerchantReviews from "./MerchantReviews";
import isDefaultBrand from "../../../util/isDefaultBrand";

const PREFIX = "MerchantDrawer";

const classes = {
  icon: `${PREFIX}-icon`,
  merchantdrawer: `${PREFIX}-merchantdrawer`,
  header: `${PREFIX}-header`,
};

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  [`& .${classes.icon}`]: {
    "& svg": {
      width: 18,
      height: 18,
    },
  },

  [`&.${classes.merchantdrawer}`]: {
    "& .MuiDrawer-paper": {
      overflowX: "hidden",
      width: 375,
      "@media (max-width: 320px)": {
        width: 320,
      },
    },
  },

  [`& .${classes.header}`]: {
    border: `1px solid ${theme.palette.borders.light}`,
    width: 375,
    "@media (max-width: 320px)": {
      width: 320,
    },
  },
}));

const MerchantDrawer = ({
  activePin,
  drawer,
  map,
  marks,
  setActivePin,
  setDrawer,
}) => {
  const theme = useTheme();

  const { cityView, googleData, setGoogleData } = useContext(MapContext);
  const [loading, setLoading] = useState(true);
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const [expandedReviews, setExpandedReviews] = useState([]);

  const GrayBorder = () => (
    <Box bgcolor="background.light" height={theme.spacing(3)} width="100%" />
  );

  useEffect(() => {
    async function getData(id) {
      if (googleData && googleData[id]) {
        setLoading(false);
        return googleData[id];
      } else {
        const value = await getMerchantContent(id, map, theme.lang);

        let address = value.formatted_address;
        if (
          ["New York", "Los Angeles", "Miami", "Honolulu"].includes(cityView)
        ) {
          const streetnum = value.address_components.filter((a) =>
            a.types.includes("street_number")
          )[0];
          const street = value.address_components.filter((a) =>
            a.types.includes("route")
          )[0];
          address = `${streetnum ? streetnum.long_name : ""} ${
            street ? street.long_name : ""
          }`;

          if (address === " ") {
            address = value.formatted_address;
          }
        }

        delete value.address_components;

        const objData = {
          [id]: {
            name:
              theme.lang === "en"
                ? drawer.name
                : googleData[drawer.google_place_id]?.name ?? drawer.name,
            address: address,
            mcc: drawer.mcc,
            ...value,
          },
        };
        setGoogleData(
          Object.keys(googleData).length
            ? { ...googleData, ...objData }
            : objData
        );
        return objData;
      }
    }

    getData(drawer.google_place_id);

    if (
      googleData[drawer.google_place_id] &&
      googleData[drawer.google_place_id].reviews
    ) {
      setLoading(false);
      const reviewsArray = [
        ...Array(
          googleData[drawer.google_place_id]?.reviews.filter(
            (d) => d.rating >= 3
          ).length
        ),
      ].map((x) => false);
      setExpandedReviews(reviewsArray);
    }
  }, [cityView, googleData, map, drawer, setGoogleData, theme.lang]);

  const drawerOnClose = () => {
    if (activePin) {
      activePin.set("active", false);
      activePin.setIcon(
        `${!isDefaultBrand(theme) ? `/brand/${theme.brand}/` : "/"}${
          activePin.merchantData.mcc
        }_default.svg`
      );
      //activePin.setIcon(`/${activePin.merchantData.mcc}_default.svg`);
      setActivePin(null);
    }
    setDrawer(null);
  };

  return (
    <StyledDrawer
      style={{ overflowX: "hidden" }}
      anchor="right"
      open={true}
      onClose={drawerOnClose}
      className={classes.merchantdrawer}
    >
      <Box bgcolor="common.white">
        <Box
          height="62px"
          display="flex"
          alignItems="center"
          paddingRight={theme.spacing(5)}
          position="fixed"
          className={classes.header}
          bgcolor="common.white"
          zIndex={1}
        >
          {" "}
          {mobile && (
            <Box component="span" textAlign="center" width="100%">
              {cityView}
            </Box>
          )}
          <CloseButton style={{ marginLeft: "auto" }} onClick={drawerOnClose} />
        </Box>

        <Box marginTop="62px">
          <MerchantImage
            drawer={drawer}
            googleData={googleData}
            loading={loading}
          />

          <Box paddingX={5} paddingY={7}>
            <Typography variant="h2">
              <b>
                {theme.lang === "en"
                  ? drawer.name
                  : googleData[drawer.google_place_id]?.name ?? drawer.name}
              </b>
            </Typography>

            <Box
              display="flex"
              alignItems="center"
              color="text.secondary"
              marginTop={3}
              marginBottom={3}
            >
              <span className={classes.icon}>
                {CATEGORIES.find((c) => c.mcc === drawer.mcc)?.icon}
              </span>
              {googleData[drawer.google_place_id]?.price_level && (
                <Typography
                  component="span"
                  color="textSecondary"
                  style={{
                    paddingLeft: theme.spacing(3),
                  }}
                >
                  {[
                    ...Array(googleData[drawer.google_place_id].price_level),
                  ].map(() => "$")}
                  <span style={{ position: "relative", left: "6px" }}>
                    &bull;
                  </span>
                </Typography>
              )}

              <Typography
                component="span"
                color="textSecondary"
                style={{
                  paddingLeft: theme.spacing(3),
                }}
              >
                {t__(
                  theme.lang,
                  common,
                  `categories.${CATEGORIES.find(
                    (c) => c.mcc === drawer.mcc
                  )?.title.toLowerCase()}`
                )}
              </Typography>
            </Box>
            {loading ? (
              <Skeleton height={theme.spacing(7)} />
            ) : (
              <Ratings
                data={googleData[drawer.google_place_id]}
                hideOpenNow
                textColor="secondary"
                variant="body2"
              />
            )}
            <Box color="textSecondary" mt={6}>
              <Typography variant="body2">
                {t__(theme.lang, common, "acceptanceLine")}
              </Typography>
              <AcceptanceMarks
                marks={marks}
                variant="small"
                style={{ marginTop: theme.spacing(2) }}
              />
            </Box>
          </Box>

          <GrayBorder />

          <MerchantHours
            drawer={drawer}
            googleData={googleData}
            loading={loading}
          />

          <GrayBorder />

          <MerchantReviews
            drawer={drawer}
            expandedReviews={expandedReviews}
            googleData={googleData}
            loading={loading}
            setExpandedReviews={setExpandedReviews}
          />
        </Box>
      </Box>
    </StyledDrawer>
  );
};

MerchantDrawer.propTypes = {
  drawer: PropTypes.object.isRequired,
  map: PropTypes.object.isRequired,
  marks: PropTypes.array,
  setDrawer: PropTypes.func.isRequired,
};

export default MerchantDrawer;
