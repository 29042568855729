import PropTypes from "prop-types";
import { styled } from '@mui/material/styles';
import { IconButton } from "@mui/material/";
import infoSvg from "../Icons/info.svg";

const PREFIX = 'InfoButton';

const classes = {
  infoButton: `${PREFIX}-infoButton`
};

const StyledIconButton = styled(IconButton)((
  {
    theme
  }
) => ({
  [`&.${classes.infoButton}`]: {
    fontSize: 0,
    "&:focus": {
      background: theme.palette.background.light,
    },
  }
}));

const InfoButton = ({ alt, onClick, ...props }) => {

  return (
    <StyledIconButton className={classes.infoButton} onClick={onClick} size="large">
      <img src={infoSvg} alt={alt} />
    </StyledIconButton>
  );
};

InfoButton.propTypes = {
  alt: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  props: PropTypes.object,
};

export default InfoButton;
