import React, { useState } from "react";
import {
  Box,
  Dialog,
  Grid,
  List,
  ListItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material/";
import trans from "../../util/lang/acceptanceLevels";
import common from "../../util/lang/common";
import t__ from "../../util/lang/util";
import InfoButton from "../InfoButton";
import CloseButton from "../CloseButton";
import { MAPCOLORS } from "../Map/mapstyles";
import isDefaultBrand from "../../util/isDefaultBrand";
import adjustColor from "../../util/adjustColor";

const MapLegendModal = ({ ...props }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const levels = trans[theme.lang].levels ?? trans["en"].levels;
  const mapColors = isDefaultBrand(theme)
    ? MAPCOLORS.default
    : {
        "NO COVERAGE": "#f2f2f2",
        J: adjustColor(theme.palette.brand.map, 0.2),
        G: adjustColor(theme.palette.brand.map, 0.45),
        H: theme.palette.brand.map,
      };

  return (
    <Box marginTop="-15px">
      <InfoButton
        alt="Acceptance Level information modal"
        onClick={() => setOpen(true)}
      />

      <Dialog
        aria-labelledby="acceptance-modal-title"
        open={open}
        fullScreen={fullScreen}
        onClose={() => setOpen(false)}
        id="acceptance-modal"
        maxWidth="md"
        sx={{
          "& .MuiPaper-rounded": {
            borderRadius: 3,
            marginTop: "150px",
            [theme.breakpoints.down("sm")]: {
              borderRadius: 0,
              marginTop: 0,
            },
          },
          "& .MuiDialog-container": {
            alignItems: "flex-start",
          },
        }}
      >
        <Box paddingX={12} paddingY={7} position="relative">
          <CloseButton
            onClick={() => setOpen(false)}
            alt={t__(theme.lang, common, "closeModal")}
            topRight={true}
          />
          <Typography variant="h4" component="p" id="acceptance-modal-title">
            <b>{t__(theme.lang, trans, "modal.headline")}</b>
          </Typography>
          <List>
            {Object.keys(levels)
              .reverse()
              .map((o, ind) => {
                const len = Object.keys(levels).length;
                return (
                  <ListItem
                    key={levels[o].title}
                    disableGutters
                    divider={len - 1 !== ind}
                    sx={{
                      pt: 4,
                      pb: 4,
                    }}
                  >
                    <Grid container>
                      <Grid item xs={12} md={4}>
                        <Box
                          display="flex"
                          alignItems="center"
                          mb={{ xs: 3, md: 0 }}
                        >
                          <Box
                            border={1}
                            width={30}
                            height={12}
                            borderRadius="6px"
                            mr={4}
                            bgcolor={mapColors[o]}
                          />

                          <Typography variant="body2">
                            <b>{levels[o].title}</b>
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <Typography
                          variant="body2"
                          color={
                            isDefaultBrand(theme)
                              ? "textSecondary"
                              : theme.palette.brand.modalLegendText
                          }
                        >
                          {levels[o].desc}
                        </Typography>
                      </Grid>
                    </Grid>
                  </ListItem>
                );
              })}
          </List>
        </Box>
      </Dialog>
    </Box>
  );
};

export default MapLegendModal;
