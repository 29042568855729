const transformCitiesData = (data, lang) =>
  data.map((d) => ({
    atmList: d._id.atmList,
    city: d._id["city"],
    translated_city:
      lang === "en" || undefined ? d._id["city"] : d._id.trans[lang].city,
    route: d._id["city"].replace(" ", "").toLowerCase(),
    country: d._id.country,
    lat: d._id["lat"],
    long: d._id["long"],
    img: d._id["images"].DCI["city_image_mobile"],
    description:
      lang === "en" || undefined
        ? d._id["description"]
        : d._id.trans[lang].description,
  }));

export default transformCitiesData;
