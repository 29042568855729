import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
} from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Drawer,
  Link,
  List,
  ListItem,
  ListItemIcon,
  Typography,
  useTheme,
} from "@mui/material/";
import searchIcon from "../Icons/icon-mini-search.svg";
import { MapContext } from "../../contexts";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CloseButton from "../CloseButton";
import common from "../../util/lang/common";
import t__ from "../../util/lang/util";
import cx from "classnames";
import pinIcon from "../Icons/city.svg";
import CityCircle from "../CityCircle";
import countryFlag from "../Icons/country.svg";
import { countryClicked } from "../Map/mapmethods";
import "../../util/theme/flags.css";
import rawCountryData from "../../data/countries_new.json";
import { useHistory } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import isDefaultBrand from "../../util/isDefaultBrand";
import trackLocationChange from "../../util/trackLocationChange";
import { visuallyHidden } from "@mui/utils";

const PREFIX = "SearchDrawer";

const classes = {
  searchContainer: `${PREFIX}-searchContainer`,
  searchBox: `${PREFIX}-searchBox`,
  focused: `${PREFIX}-focused`,
  searchInput: `${PREFIX}-searchInput`,
  hasCity: `${PREFIX}-hasCity`,
  backButton: `${PREFIX}-backButton`,
  closex: `${PREFIX}-closex`,
  searchIcon: `${PREFIX}-searchIcon`,
  cityIcon: `${PREFIX}-cityIcon`,
  results: `${PREFIX}-results`,
  mapLink: `${PREFIX}-mapLink`,
};

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  [`& .${classes.searchContainer}`]: {
    overflowX: "hidden",
    width: 375,
    "@media (max-width: 320px)": {
      width: 320,
    },
  },

  [`& .${classes.searchBox}`]: {
    width: "100%",
    height: theme.spacing(9),
    borderColor: theme.palette.borders.light,
    borderRadius: theme.spacing(4.5),
    border: `1px solid ${theme.palette.borders.light}`,
    marginLeft: theme.spacing(1),
    position: "relative",
  },

  [`& .${classes.focused}`]: {
    borderColor: theme.palette.primary.main,
  },

  [`& .${classes.searchInput}`]: {
    width: "99%",
    height: theme.spacing(8.5),
    margin: "0px 1px",
    padding: "0px 58px 0 20px",
    border: "none",
    textTransform: "none !important",
    borderRadius: theme.spacing(4.25),
    outline: "none",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.primary,
    "&::placeholder": {
      color: theme.palette.text.secondary,
    },
    background: "transparent",
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.body1.fontSize,
    },
  },

  [`& .${classes.hasCity}`]: {
    paddingLeft: "30px",
    "&::placeholder": {
      color: theme.palette.text.primary,
    },
  },

  [`& .${classes.backButton}`]: {
    minWidth: 0,
    height: theme.spacing(9),
    width: theme.spacing(9),
    borderRadius: theme.spacing(4.5),
    "& svg": {
      margin: "0 auto",
      position: "relative",
      right: "-5px",
    },
  },

  [`& .${classes.closex}`]: {
    position: "absolute",
    right: 5,
    top: 0,
    width: 34,
    height: 34,
    borderRadius: 34,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 0,
    "& img": {
      height: 20,
      width: 20,
    },
  },

  [`& .${classes.searchIcon}`]: {
    top: 7,
  },

  [`& .${classes.cityIcon}`]: {
    position: "absolute",
    left: 12,
    top: 10,
  },

  [`& .${classes.results}`]: {
    padding: `${theme.spacing(4)} 0 0 0`,
    marginLeft: `-${theme.spacing(2)}`,
    "& span": {
      display: "block",
    },
  },

  [`& .${classes.mapLink}`]: {
    display: "block",
    marginBottom: theme.spacing(5),
    color: `${theme.palette.secondary.main} !important`,
  },
}));

const SearchDrawer = ({ map, setFilteredMerchants, zoom }) => {
  const theme = useTheme();

  const history = useHistory();
  //const smScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const {
    acceptanceData,
    cities,
    cityView,
    searchDrawer,
    setCityView,
    setFilter,
    setMapLoading,
    setMerchants,
    setSearchDrawer,
    setSelectedCountry,
    setLocationShared,
    locationShared,
    activeTooltip,
  } = useContext(MapContext);
  const [inputFocused, setInputFocused] = useState(false);
  const [textEntered, setTextEntered] = useState("");
  const defaultCities = useMemo(() => ["Paris", "Barcelona", "New York"], []);
  const [cityResults, setCityResults] = useState(defaultCities);
  const [countryResults, setCountryResults] = useState(["JPN", "AUS", "CHL"]);
  const [countryList, setCountryList] = useState(null);
  const [cityList, setCityList] = useState(null);
  const [loader, setLoader] = useState(true);

  const revisedCities = cities;
  const getCountryName = useCallback(
    (c) => {
      const cityData = revisedCities.find((d) => d.city === c);
      if (!cityData) {
        return;
      }
      const found = acceptanceData.find((a) => a.cca3 === cityData.country);
      const country = found?.translated_name ?? found?.common_name;
      return country || "";
    },
    [acceptanceData]
  );

  useEffect(() => {
    setLoader(true);
    setTimeout(function () {
      setLoader(false);
    }, 2000);
  }, [searchDrawer]);

  useEffect(() => {
    if (!acceptanceData || !cities) {
      return;
    }
    const updatedAcceptance = acceptanceData.filter((a) =>
      rawCountryData.features.find((f) => f.properties.adm0_a3 === a.cca3)
    );

    let baseList = updatedAcceptance.map((a) => {
      const whichname = a.translated_name ?? a.common_name;
      return { name: whichname?.toLowerCase(), code: a.cca3 };
    });

    //a bunch of fun variations that I am shoving in here.
    baseList.push({ name: "u.s. virgin islands", code: "VIR" });
    baseList.push({ name: "us virgin islands", code: "VIR" });
    baseList.push({ name: "virgin islands", code: "VIR" });
    baseList.push({ name: "usvi", code: "VIR" });
    baseList.push({ name: "st thomas", code: "VIR" });
    baseList.push({ name: "st. thomas", code: "VIR" });
    baseList.push({ name: "saint thomas", code: "VIR" });
    setCountryList(baseList);

    setCityList(
      revisedCities.map((c) => {
        return {
          name: c.translated_city ?? c.city,
          translated: c.translated_city,
          country: getCountryName(c.city),
        };
      })
    );
  }, [acceptanceData, cities, getCountryName]);

  useEffect(() => {
    if (textEntered === "") {
      setCityResults(
        revisedCities?.map((c) =>
          defaultCities.includes(c.city) ? c.translated_city ?? c.city : ""
        ) || defaultCities
      );
      setCountryResults(["FRA", "ESP", "USA"]);
      return;
    }
    const countries = [];
    const citys = [];
    countryList?.forEach((l) => {
      if (
        l.name.toLowerCase().substring(0, textEntered.length) === textEntered &&
        textEntered !== ""
      ) {
        countries.push(l.code);
        if (["PRI", "DOM", "JAM", "VIR", "BHS"].includes(l.code)) {
          citys.push("Caribbean");
        }
      }
    });
    cityList?.forEach((c) => {
      if (
        c.name.toLowerCase().substring(0, textEntered.length) === textEntered ||
        (c.country.toLowerCase().substring(0, textEntered.length) ===
          textEntered &&
          textEntered !== "")
      ) {
        citys.push(c.name);
        if (c.name === "San Juan") {
          citys.push("Caribbean");
        }
      }
    });
    setCountryResults([...new Set(countries)]);
    setCityResults([...new Set(citys)].sort());
  }, [
    cityList,
    countryList,
    //revisedCities,
    textEntered,
    cities,
    defaultCities,
  ]);

  const resetInput = () => {
    setTextEntered("");
  };

  return (
    <StyledDrawer
      anchor="right"
      open={searchDrawer}
      onClose={() => {
        resetInput();
        setSearchDrawer(false);
      }}
    >
      <Box
        className={classes.searchContainer}
        bgcolor="common.white"
        padding={5}
      >
        <Box display="flex" marginBottom={7}>
          <Button
            className={classes.backButton}
            onClick={() => {
              resetInput();
              setSearchDrawer(false);
            }}
          >
            <ArrowBackIosIcon color="secondary" />
          </Button>
          <Box
            className={cx(classes.searchBox, inputFocused && classes.focused)}
            bgcolor={
              cityView ? theme.palette.background.medLight : "transparent"
            }
          >
            {cityView && !textEntered && (
              <img
                src={pinIcon}
                alt="city icon"
                width={14}
                height={14}
                className={classes.cityIcon}
              />
            )}
            <input
              placeholder={t__(theme.lang, common, "searchDefault")}
              className={cx(classes.searchInput, cityView && classes.hasCity)}
              onFocus={() => setInputFocused(true)}
              onBlur={() => setInputFocused(false)}
              onChange={(e) => {
                setTextEntered(e.target.value.toLowerCase());
              }}
              value={textEntered}
              autoFocus
              aria-describedby="searchInfo"
            />
            <Typography style={visuallyHidden} id="searchInfo">
              After entering your search text please tab for your results below
            </Typography>
            {textEntered ? (
              <CloseButton className={classes.closex} onClick={resetInput} />
            ) : (
              <Box
                borderLeft={1}
                height="34px"
                position="absolute"
                right={0}
                top={0}
                width={theme.spacing(12)}
                borderColor="borders.light"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <img
                  src={searchIcon}
                  alt={t__(theme.lang, common, "search")}
                  height={20}
                  width={20}
                />
              </Box>
            )}
          </Box>
        </Box>
        <Link
          href={
            isDefaultBrand(theme) ? `/guides` : `/guides/?brand=${theme.brand}`
          }
          className={classes.mapLink}
        >
          <Typography variant="body2">
            <b>{t__(theme.lang, common, "exploreTheGlobalMap")}</b>
          </Typography>
        </Link>

        <Box tabIndex={0} style={{ outline: "none" }}>
          {countryResults.length === 0 && cityResults.length === 0 ? (
            <>
              <Typography variant="h2" component="p">
                <b>
                  {t__(theme.lang, common, "noResults")}"{textEntered}"
                </b>
              </Typography>
              <Box mt={6}>
                <Typography variant="body2">
                  {t__(theme.lang, common, "noResultsCopy")}
                </Typography>
              </Box>
            </>
          ) : (
            <>
              <Typography variant="h2" component="p">
                <b>
                  {textEntered
                    ? t__(theme.lang, common, "yourResults")
                    : t__(theme.lang, common, "trendingDestinations")}
                </b>
              </Typography>

              {((cities && countryResults && cityResults.length !== 0) ||
                (cities && countryResults && countryResults.length !== 0)) && (
                <Box
                  marginTop={7}
                  paddingBottom={7}
                  borderBottom={1}
                  style={{ borderColor: theme.palette.borders.light }}
                >
                  {loader ? (
                    <Skeleton animation="wave" />
                  ) : (
                    <Typography variant="body2">
                      <b>{t__(theme.lang, common, "travelGuides")}</b>
                    </Typography>
                  )}
                  <List className={classes.results}>
                    {cityResults?.map((c) => {
                      const cityData = cities?.find(
                        (d) => d.translated_city === c
                      );

                      const country = getCountryName(cityData?.city);
                      if (cityData) {
                        return (
                          <li key={c}>
                            <ListItem
                              button
                              disableGutters
                              onClick={() => {
                                if (locationShared.length > 0)
                                  locationShared[0].setMap(null);
                                setLocationShared([]);
                                resetInput();
                                setSearchDrawer(false);
                                window.scrollTo(0, 0);
                                setMapLoading(true);
                                setMerchants(null);
                                setFilter(null);
                                setFilteredMerchants(null);
                                setCityView(
                                  cities.find((i) => i.translated_city === c)
                                    .city
                                );
                                isDefaultBrand(theme)
                                  ? history.push(`/guides/${cityData.route}`)
                                  : history.push(
                                      `/guides/${cityData.route}?brand=${theme.brand}`
                                    );
                                trackLocationChange();
                              }}
                            >
                              <ListItemIcon>
                                {loader ? (
                                  <Skeleton
                                    variant="circular"
                                    width={40}
                                    height={40}
                                  />
                                ) : (
                                  <CityCircle
                                    border={1}
                                    cityImg={cityData.img}
                                    width={40}
                                  />
                                )}
                              </ListItemIcon>
                              {loader ? (
                                <Skeleton
                                  animation="wave"
                                  width="100%"
                                  height={40}
                                />
                              ) : (
                                <Box paddingLeft={2}>
                                  <Typography
                                    variant="body2"
                                    component="span"
                                    style={
                                      !isDefaultBrand(theme)
                                        ? {
                                            color:
                                              theme.palette.brand
                                                .searchFlyoutHeader,
                                          }
                                        : {
                                            color: theme.palette.primary.main,
                                          }
                                    }
                                  >
                                    <b>
                                      {cityData.translated_city ??
                                        cityData.city}
                                    </b>
                                  </Typography>
                                  <Typography
                                    variant="caption"
                                    component="span"
                                  >
                                    {cityData.city === "Caribbean"
                                      ? "Islands"
                                      : country}
                                  </Typography>
                                </Box>
                              )}
                            </ListItem>
                          </li>
                        );
                      } else return null;
                    })}
                  </List>
                </Box>
              )}
              {acceptanceData && countryResults.length !== 0 && (
                <Box
                  marginTop={7}
                  marginBottom={7}
                  borderBottom={1}
                  paddingBottom={7}
                  style={{ borderColor: theme.palette.borders.light }}
                >
                  {loader ? (
                    <Skeleton animation="wave" height={theme.spacing(10)} />
                  ) : (
                    <Typography variant="body2">
                      <b>{t__(theme.lang, common, "countries")}</b>
                    </Typography>
                  )}
                  <List className={classes.results}>
                    {countryResults.map((c) => {
                      const feature = rawCountryData.features.find(
                        (f) => f.properties.adm0_a3 === c
                      );
                      if (feature) {
                        const found = acceptanceData.find((a) => a.cca3 === c);
                        const foundName =
                          found.translated_name ?? found.common_name;
                        return (
                          <li key={feature.properties["adm0_a3"]}>
                            <ListItem
                              button
                              disableGutters
                              key={c}
                              onClick={() => {
                                let clicked = null;
                                map.data.forEach((feature) => {
                                  if (feature.getProperty("adm0_a3") === c) {
                                    clicked = feature;
                                    return;
                                  }
                                });
                                resetInput();
                                setSearchDrawer(false);
                                window.scrollTo(0, 0);
                                setCityView(false);
                                setMerchants(null);
                                setFilter(null);
                                if (locationShared.length > 0)
                                  locationShared[0].setMap(null);
                                setLocationShared([]);
                                map.setZoom(zoom);
                                countryClicked(
                                  map,
                                  setSelectedCountry,
                                  activeTooltip,
                                  theme,
                                  "",
                                  clicked
                                );
                              }}
                            >
                              <ListItemIcon>
                                {loader ? (
                                  <Skeleton
                                    variant="circular"
                                    width={40}
                                    height={40}
                                  />
                                ) : (
                                  <Box
                                    width={theme.spacing(10)}
                                    height={theme.spacing(10)}
                                    borderRadius={theme.spacing(10)}
                                    bgcolor={theme.palette.background.light}
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    marginLeft={theme.spacing(2)}
                                  >
                                    {feature && feature.properties ? (
                                      <Box
                                        className={`flag:${feature.properties["iso_a2"]}`}
                                        marginRight={0}
                                      ></Box>
                                    ) : (
                                      <img src={countryFlag} alt="flag" />
                                    )}
                                  </Box>
                                )}
                              </ListItemIcon>
                              {loader ? (
                                <Skeleton
                                  animation="wave"
                                  width="100%"
                                  height={40}
                                />
                              ) : (
                                <Box
                                  paddingLeft={2}
                                  style={
                                    !isDefaultBrand(theme)
                                      ? {
                                          color:
                                            theme.palette.brand
                                              .searchFlyoutHeader,
                                        }
                                      : {
                                          color: theme.palette.primary.main,
                                        }
                                  }
                                >
                                  <Typography variant="body2">
                                    <b>{foundName}</b>
                                  </Typography>
                                </Box>
                              )}
                            </ListItem>
                          </li>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </List>
                </Box>
              )}
            </>
          )}
        </Box>
      </Box>
    </StyledDrawer>
  );
};

SearchDrawer.propTypes = {
  map: PropTypes.object,
  setFilteredMerchants: PropTypes.func.isRequired,
  zoom: PropTypes.number,
};

export default SearchDrawer;
