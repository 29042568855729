import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Drawer, Typography, useTheme } from "@mui/material/";
import CloseButton from "../CloseButton";
import caribbean from "../../util/lang/caribbean";
import t__ from "../../util/lang/util";
import isDefaultBrand from "../../util/isDefaultBrand";
import AtmBox from "../AtmBox";
import AllCardsBox from "../AllCardsBox";
import TravelGuidesLogo from "../CountryView/TravelGuidesLogo";
import CityTile from "../CountryView/CityTile";

const PREFIX = "MerchantDrawer";

const classes = {
  icon: `${PREFIX}-icon`,
  merchantdrawer: `${PREFIX}-merchantdrawer`,
  header: `${PREFIX}-header`,
};

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  [`& .${classes.icon}`]: {
    "& svg": {
      width: 18,
      height: 18,
    },
  },

  [`&.${classes.merchantdrawer}`]: {
    "& .MuiDrawer-paper": {
      overflowX: "hidden",
      width: 375,
      transition: "all .4s",
      "@media (max-width: 320px)": {
        width: 320,
      },
    },
  },

  [`& .${classes.header}`]: {
    border: `1px solid ${theme.palette.borders.light}`,
    width: 375,
    "@media (max-width: 320px)": {
      width: 320,
    },
  },
}));

const images = {
  Bahamas: "Bahamas",
  "Puerto Rico": "PuertoRico",
  "Dominican Republic": "DominicanRepublic",
  Jamaica: "Jamaica",
  "US Virgin Islands / St. Thomas": "USVirginIslands",
};

const CaribbeanDrawer = ({ drawer, setDrawer, countryData, cities }) => {
  const theme = useTheme();
  const hasCreditCoverage = countryData.credit_level !== "NO COVERAGE";
  const cityGuides = cities.filter((c) => c.country === countryData.cca3);

  const GrayBorder = () => (
    <Box bgcolor="background.light" height={theme.spacing(3)} width="100%" />
  );

  const drawerOnClose = () => {
    setDrawer(null);
  };

  return (
    <StyledDrawer
      style={{ overflowX: "hidden" }}
      anchor="right"
      open={drawer ? true : false}
      onClose={drawerOnClose}
      className={classes.merchantdrawer}
    >
      <Box bgcolor="common.white">
        <Box
          height="62px"
          display="flex"
          alignItems="center"
          paddingRight={theme.spacing(5)}
          position="fixed"
          className={classes.header}
          bgcolor="common.white"
          zIndex={1}
        >
          <Box component="span" textAlign="center" width="100%">
            {drawer}
          </Box>

          <CloseButton style={{ marginLeft: "auto" }} onClick={drawerOnClose} />
        </Box>

        <Box marginTop="62px">
          <Box
            bgcolor={
              isDefaultBrand(theme)
                ? "secondary.light"
                : theme.palette.brand.primaryBg
            }
          >
            <Box
              height="240px"
              width="100%"
              style={{
                borderBottomRightRadius: "120px",
                overflow: "hidden",
                position: "relative",
              }}
            >
              <img
                src={`/destinations/Destinations_${images[drawer]}.jpg`}
                className={classes.imagecover}
                alt=""
                style={{
                  position: "absolute",
                  left: "-10000%",
                  right: "-10000%",
                  top: "-10000%",
                  bottom: "-10000%",
                  margin: "auto auto",
                  minWidth: "1000%",
                  minHeight: "1000%",
                  "-webkit-transform": "scale(0.1)",
                  transform: "scale(0.1)",
                }}
              />
            </Box>
          </Box>

          <Box paddingX={5} paddingY={7}>
            <Typography variant="h2">
              <b>
                {t__(theme.lang, caribbean, "confidently")}
                {drawer === "Bahamas" ||
                drawer === "US Virgin Islands / St. Thomas" ||
                drawer === "Dominican Republic"
                  ? " the"
                  : null}{" "}
                {drawer}
              </b>
            </Typography>

            <Typography variant="body1" sx={{ paddingTop: theme.spacing(3) }}>
              {drawer &&
                caribbean[theme.lang].destinations.find(
                  (c) => c.title === drawer
                ).drawerDescription}
            </Typography>
          </Box>

          <GrayBorder />
          <Box paddingX={5} paddingY={7}>
            <AllCardsBox
              countryData={countryData}
              hasCreditCoverage={hasCreditCoverage}
              inDrawer={true}
            />
          </Box>
          <Box paddingX={5} paddingBottom={7}>
            <AtmBox countryData={countryData} inDrawer={true} />
          </Box>
          {cityGuides.length > 0 && (
            <>
              <GrayBorder />
              <Box paddingX={5} paddingY={7}>
                <TravelGuidesLogo />
              </Box>
              {cityGuides.map((c) => (
                <Box paddingX={5} paddingBottom={7}>
                  <CityTile
                    city={cityGuides[0]}
                    route={cityGuides[0].route}
                    height={{
                      xs: theme.spacing(29),
                      sm: theme.spacing(32),
                      md: theme.spacing(40),
                    }}
                  />
                </Box>
              ))}
            </>
          )}
        </Box>
      </Box>
    </StyledDrawer>
  );
};

export default CaribbeanDrawer;
