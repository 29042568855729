import React, { useContext } from "react";
import { MapContext } from "../../contexts";
import Container from "../Layout/Container";
import isDefaultBrand from "../../util/isDefaultBrand";
import t__ from "../../util/lang/util";
import caribbean from "../../util/lang/caribbean";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CaribbeanDrawer from "./CaribbeanDrawer";

import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material/";

const Destinations = ({ acceptanceData, cities }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.up("md"));
  const lgScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const { drawer, setDrawer } = useContext(MapContext);
  return (
    <>
      <Container sx={{ color: "text.primary" }}>
        <Typography
          fontSize={{ xs: 24, md: 32 }}
          align="center"
          mt={{ xs: 10, md: 20 }}
          component="h2"
        >
          <b>{t__(theme.lang, caribbean, "headline")}</b>
        </Typography>
        <Box
          display="flex"
          width="100%"
          mt={10}
          flexWrap="wrap"
          justifyContent="space-between"
        >
          {caribbean[theme.lang].destinations.map((d, ind) => (
            <Card
              sx={{
                width: { xs: "100%", md: "48.5%", lg: d.width },
                flexShrink: 0,
                color: theme.palette.secondary.main,
                position: "relative",
                "&:hover h4": { textDecoration: "underline" },
                marginBottom: 5,
              }}
              elevation={0}
              key={d.title}
            >
              <CardMedia
                sx={{
                  height: 180,
                  backgroundColor: theme.palette.common.white,
                  borderTopLeftRadius: 20,
                  borderTopRightRadius: 20,
                  position: "relative",
                  "&:after": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    zIndex: 1,
                  },
                }}
                image={d.img}
              ></CardMedia>
              <CardContent
                style={{
                  padding: fullScreen
                    ? lgScreen
                      ? theme.spacing(10)
                      : theme.spacing(5)
                    : theme.spacing(4),
                  paddingBottom: fullScreen
                    ? lgScreen
                      ? theme.spacing(7)
                      : theme.spacing(4)
                    : theme.spacing(3),
                  backgroundColor: theme.palette.background.light,
                  color: theme.palette.text.primary,
                  display: "flex",
                  flexDirection: "column",
                  borderBottomLeftRadius: 20,
                  borderBottomRightRadius: 20,
                }}
              >
                <Typography
                  fontSize={{ xs: theme.spacing(4), md: theme.spacing(5) }}
                  component="h3"
                >
                  <b>{d.title}</b>
                </Typography>
                <Box mt={2} minHeight={{ md: theme.spacing(22) }}>
                  <Typography
                    fontSize={{ xs: theme.spacing(3.5), md: theme.spacing(4) }}
                  >
                    {d.description}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-end",
                    flexWrap: "wrap",
                  }}
                >
                  <Button
                    onClick={() => setDrawer(d.title)}
                    sx={{
                      display: "inline-flex",
                      justifyContent: "start",
                      alignItems: "center",
                      height: "44px",
                      padding: 0,
                      margin: 0,
                      width: "auto",
                      textAlign: "left",

                      textTransform: "none",
                      marginTop: { xs: theme.spacing(5), md: 0 },
                      color: theme.palette.secondary.main,
                      fontWeight: "bold",
                      "&:hover, &:focus": {
                        backgroundColor: theme.palette.background.light,
                        textDecoration: "underline",
                      },
                    }}
                  >
                    <span>See Details</span>
                    <ArrowForwardIcon
                      style={{
                        marginLeft: 5,
                        color: theme.palette.primary.light,
                      }}
                    />
                  </Button>
                  {d.title === "Puerto Rico" && (
                    <Button
                      sx={{
                        fontSize: { xs: 16, lg: 19 },
                        fontWeight: "bold",
                        color: `${theme.palette.common.white} !important`,
                        textTransform: "none",
                        whiteSpace: "nowrap",
                        height: 44,
                        padding: "0 24px",
                        borderRadius: 20,

                        backgroundColor: `${
                          isDefaultBrand(theme)
                            ? theme.palette.primary.light
                            : theme.palette.primary.main
                        }`,
                        "&:hover, &:focus": {
                          backgroundColor: `${
                            isDefaultBrand(theme)
                              ? "#E94D00"
                              : theme.palette.brand.primaryBg
                          }`,
                        },
                      }}
                      href={`/guides/sanjuan${
                        !isDefaultBrand(theme) ? `?brand=${theme.brand}` : ``
                      }`}
                    >
                      {t__(theme.lang, caribbean, `exploreSanJuan`)}
                    </Button>
                  )}
                </Box>
              </CardContent>
            </Card>
          ))}
        </Box>
      </Container>

      <CaribbeanDrawer
        drawer={drawer}
        setDrawer={setDrawer}
        cities={cities}
        countryData={
          acceptanceData.find((c) => c.common_name === drawer) ||
          acceptanceData.find(
            (e) => e.common_name === "United States Virgin Islands"
          )
        }
      />
    </>
  );
};
export default Destinations;
