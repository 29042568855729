import PropTypes from "prop-types";
import useCountries from "../api/useCountries";
import useCountryAcceptance from "../api/useCountryAcceptance";
import useCityGuideData from "../api/useCityGuideData";
import Intro from "./Intro";
import Caribbean from "./Caribbean";
import React from "react";
import Map from "./Map";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { Box, CircularProgress, useTheme } from "@mui/material/";
import transformCitiesData from "./Map/transforms";
import TechDiff from "./TechDiff";

const Content = ({ ip }) => {
  const theme = useTheme();
  const {
    status: countriesStatus,
    data: countriesData,
    error: countriesError,
  } = useCountries();
  const {
    status: countryStatus,
    data: countryData,
    error: countryError,
  } = useCountryAcceptance();
  const {
    status: citiesStatus,
    data: citiesData,
    error: citiesError,
  } = useCityGuideData("cities", {
    pagesize: 1000,
  });

  if (countriesError || countryError || citiesError) return <TechDiff />;
  if (
    countriesStatus === "loading" ||
    countryStatus === "loading" ||
    citiesStatus === "loading"
  ) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        width="100vw"
        bgcolor="common.white"
      >
        <CircularProgress color="secondary" />
      </Box>
    );
  }

  if (countriesData && countryData && citiesData) {
    const lookup = {
      en: "en",
      es: "spa", // strangely called spa
    };

    const langDefinition = lookup[theme.lang];
    const transformedCountryData = countryData.result.map((m) => ({
      ...m,
      translated_name:
        countriesData.result.find((c) => c.cca3 === m.cca3).translations[
          langDefinition
        ]?.common || m.common_name,
    }));

    const transformedCities = transformCitiesData(
      citiesData?.result,
      theme.lang
    );

    transformedCities.push({
      atmList: ["CGD/SIBS"],
      city: "Caribbean",
      country: "XXX",
      description: "The Caribbean...",
      img: "/destinations/caribbean_300.jpg",
      lat: 15.1096,
      long: -77.2975,
      route: "caribbean",
      translated_city: "Caribbean",
      translated_name: "Caribbean",
    });

    return (
      <Router>
        <Switch>
          <Route exact path="/guides">
            <Map
              acceptanceData={transformedCountryData}
              cities={transformedCities}
            />
          </Route>
          <Route exact path="/en/guides/caribbean">
            <Caribbean
              acceptanceData={transformedCountryData}
              cities={transformedCities}
            />
          </Route>
          <Route exact path="/guides/caribbean">
            <Caribbean
              acceptanceData={transformedCountryData}
              cities={transformedCities}
            />
          </Route>
          <Route
            path="/guides/:city"
            render={(renderProps) => {
              const cityMatch = transformedCities.find(
                (c) => c.route === renderProps.match.params.city
              )?.city;

              return (
                <Map
                  acceptanceData={transformedCountryData}
                  cities={transformedCities}
                  defaultCityView={cityMatch}
                />
              );
            }}
          />
          <Route exact path="/techdiff">
            <TechDiff />
          </Route>

          <Route exact path="/cabal">
            <Redirect to="/?brand=cabal" />
          </Route>

          <Route path="*">
            <Intro
              countryData={transformedCountryData}
              cities={transformedCities}
              ip={ip}
            />
          </Route>
        </Switch>
      </Router>
    );
  }
};

Content.propTypes = {
  id: PropTypes.object,
};

export default Content;
