import React from "react";
import PropTypes from "prop-types";
import { List, ListItem, useTheme } from "@mui/material/";
import DN from "../Icons/DGN_AcceptanceMark_FC_Hrz_RGB.png";
import DCI from "../Icons/asset-decal-diner@2x.png";
import PUL from "../Icons/pulse.svg";
import JCB from "../Icons/JCB_EmblemColor.png";
import UP from "../Icons/US_standard.jpg";
import ELO from "../Icons/Elo_logo_color.png";
import RuPay from "../Icons/Rupay-Logo.png";
import Troy from "../Icons/Troy_logo.png";
import circleELO from "../Icons/elo-logo@3x.png";
import DinaCard from "../Icons/Logo_DinaCard.jpg";
import Verve from "../Icons/Logo_Verve.jpg";
import cabal from "../Icons/logo-cabal@2x.png";
import BC from "../Icons/BC-Global-Signature-Final.jpg";

const SPECS = {
  "BC Card": {
    width: {
      default: 59,
      small: 49,
      xs: 30,
    },
    alt: "BC Card Logo",
    img: BC,
  },
  DN: {
    width: {
      default: 87,
      small: 74,
      xs: 48,
    },
    alt: "Discover Card Logo",
    img: DN,
  },
  DCI: {
    width: {
      default: 75,
      small: 63,
      xs: 41,
    },
    alt: "Diner's Club International",
    img: DCI,
  },
  DinaCard: {
    width: {
      default: 87,
      small: 74,
      xs: 48,
    },
    alt: "DinaCard",
    img: DinaCard,
  },
  Elo: {
    width: {
      default: 75,
      small: 63,
      xs: 41,
    },
    alt: "ELO",
    img: ELO,
  },
  elo: {
    width: {
      default: 75,
      small: 63,
      xs: 32,
    },
    alt: "ELO",
    img: circleELO,
  },
  JCB: {
    width: {
      default: 75,
      small: 63,
      xs: 41,
    },
    alt: "Japan Central Bank",
    img: JCB,
  },
  PUL: {
    width: {
      default: 75,
      small: 63,
      xs: 41,
    },
    alt: "PULSE",
    img: PUL,
  },
  RuPay: {
    width: {
      default: 75,
      small: 63,
      xs: 41,
    },
    alt: "RuPay",
    img: RuPay,
  },
  Troy: {
    width: {
      default: 75,
      small: 63,
      xs: 41,
    },
    alt: "Troy",
    img: Troy,
  },
  troy: {
    width: {
      default: 75,
      small: 63,
      xs: 41,
    },
    alt: "Troy",
    img: Troy,
  },
  UP: {
    width: {
      default: 88,
      small: 75,
      xs: 49,
    },
    alt: "Union Pay",
    img: UP,
  },
  UnionPay: {
    width: {
      default: 88,
      small: 75,
      xs: 49,
    },
    alt: "Union Pay",
    img: UP,
  },
  Verve: {
    width: {
      default: 87,
      small: 74,
      xs: 48,
    },
    alt: "Verve",
    img: Verve,
  },
  Cabal: {
    width: {
      default: 158,
      small: 86,
      xs: 86,
    },
    alt: "Cabal",
    img: cabal,
  },
};

const AcceptanceMarks = ({
  marks,
  marksStyles,
  variant = "default",
  style,
  ...props
}) => {
  const theme = useTheme();
  //if no marks just use the base 2
  const cardMarks =
    !marks || marks.length === 0 || marks[0] === "" ? ["DCI", "DN"] : marks;

  return (
    <List disablePadding style={{ display: "flex", ...style }} {...props}>
      {cardMarks.map((m, ind) => {
        const item = m.trim();
        return (
          <ListItem
            disableGutters
            key={"mark" + ind}
            style={{
              width: "auto",
              marginRight:
                variant === "xs" ? theme.spacing(3) : theme.spacing(5),
              padding: 0,
              ...marksStyles,
            }}
          >
            {SPECS[item] ? (
              <img
                src={SPECS[item].img}
                alt={SPECS[item].alt}
                width={SPECS[item].width[variant]}
              />
            ) : (
              m
            )}
          </ListItem>
        );
      })}
    </List>
  );
};

AcceptanceMarks.propTypes = {
  marks: PropTypes.array,
  marksStyles: PropTypes.object,
  variant: PropTypes.oneOf(["default", "small", "xs"]),
  style: PropTypes.object,
  props: PropTypes.object,
};

export default AcceptanceMarks;
