import { palette } from "../palette";
export default {
  MuiLink: {
    styleOverrides: {
      root: {
        textDecoration: "none",
        color: palette.secondary.main,
        "&.Mui-focusVisible": {
          textDecoration: "underline",
          outline: "none",
        },
        "&:hover": {
          textDecoration: "underline",
          outline: "none",
        },
      },
    },
  },
};
