import { MarkerWithLabel } from "@googlemaps/markerwithlabel";
import MarkerClusterer from "@googlemaps/markerclustererplus";
import "../../util/theme/city_pins.css";
import "../../util/theme/merchant_pins.css";

//Note: MarkerWithLabel labelContent expects a DOM node so create these the old-fashioned way
export const makeCityPins = (p, map, theme, isBrand) => {
  return new MarkerWithLabel({
    labelContent: `
      <div class="city_pin ${!isBrand ? theme.brand : ""}">
        <div
          class="city_circle" style="background-image: url(${p.img});"
        ></div>
        <div class="text-wrap">
          <span class="city_text">
          ${p.translated_city ?? p.city}
          </span>
        </div>
      </div>
      `,
    position: { lat: p.lat, lng: p.long },
    clickable: true,
    draggable: false,
    icon: "trans-sq.png",
    map,
    merchantData: {
      ...p,
    },
  });
};

export const makeCityClusters = (cityPins, map) => {
  const clusters = new MarkerClusterer(map, cityPins, {
    averageCenter: false,
    ignoreHidden: true,
    gridSize: 45,
    minimumClusterSize: 2,
    styles: [
      {
        url: "trans-sq.png", //we don't actually show the clusterer
        height: 1,
        width: 1,
        textColor: "transparent",
      },
    ],
  });

  let removedCities = [];

  clusters.addListener("clusteringend", function () {
    const len = clusters.clusters_.length;
    const addMarker = (m) => {
      removedCities.push(m);
    };
    //using for loop here - this function will run a lot, performance ?
    for (let a = 0; a < len; a++) {
      let markers = clusters.clusters_[a].markers_;
      if (markers.length > 1) {
        markers.shift(); //can change filter here for which city gets priority display in a cluster, for now just the first one alphabetically is kept, the others get added to the array to be removed
        markers.forEach((m) => addMarker(m));
      }
    }

    if (removedCities) {
      clusters.removeMarkers(removedCities);
      clusters.addMarkers(removedCities, true); //quietly return all the markers back for the next clustering evaluation
      removedCities = [];
    }
  });
  return clusters;
};

export const makeMerchantPins = (p, map, isBrand, theme) => {
  return new MarkerWithLabel({
    labelContent: `
      <span class="merchant-label">${p.name}</span>   
      `,
    position: { lat: p.point[1], lng: p.point[0] },
    clickable: true,
    draggable: false,
    icon: `${isBrand ? `/brand/${theme.brand}/` : "/"}${p.mcc}_default.svg`,
    labelAnchor: new window.google.maps.Point(0, 0),
    map,
    merchantData: {
      ...p,
    },
  });
};

export const makeMerchantClusters = (merchantPins, map, maps) => {
  const clusters = new MarkerClusterer(map, merchantPins, {
    averageCenter: true,
    ignoreHidden: true,
    gridSize: 12,
    minimumClusterSize: 30,
    styles: [
      {
        url: "trans-sm-sq.png", //we don't actually show the clusterer
        height: 42,
        width: 42,
        textColor: "#000",
      },
    ],
  });

  let removedMerchants = [];

  maps.event.addListener(clusters, "clusteringend", function () {
    const len = clusters.clusters_.length;
    const addMarker = (m) => {
      removedMerchants.push(m);
    };
    //using for loop here - this function will run a lot, performance ?
    for (let a = 0; a < len; a++) {
      let markers = clusters.clusters_[a].markers_;
      if (markers.length > 1) {
        markers.shift(); //can change filter here for which merchant gets priority display in a cluster, for now just the first one alphabetically is kept, the others get added to the array to be removed
        markers.forEach((m) => addMarker(m));
      }
    }

    if (removedMerchants) {
      clusters.removeMarkers(removedMerchants);
      clusters.addMarkers(removedMerchants, true); //quietly return all the markers back for the next clustering evaluation
      removedMerchants = [];
    }
  });
  return clusters;
};
