import { Box, useTheme, Typography } from "@mui/material/";
import Container from "../Layout/Container";
import dgnLogo from "../Icons/DGN_AcceptanceMark_FC_Hrz_RGB.png";
import dinersLogo from "../Icons/asset-decal-diner@2x.png";
import caribbean from "../../util/lang/caribbean";
import t__ from "../../util/lang/util";

const Marquee = () => {
  const theme = useTheme();
  return (
    <>
      <Box
        width="100%"
        bgcolor="primary.main"
        color="common.white"
        paddingBottom={10}
        overflow="hidden"
        display={{ md: "none" }}
      >
        <Box display="flex" height="100%">
          <Box
            sx={{
              height: {
                xs: "175px",
                sm: "200px",
                md: "250px",
              },
              width: {
                xs: `calc(100% - 87.5px)`,
                sm: `calc(100% - 100px)`,
                md: `calc(100% - 125px)`,
              },
              backgroundColor: "#fff",
              borderTopRightRadius: "125px",
              borderBottomRightRadius: "125px",
              overflow: "hidden",
              background: "url(/caribbean_marquee_hero.jpg) center no-repeat",
              backgroundSize: "cover",
            }}
            data-aos="fade-right"
            data-aos-delay={500}
            data-aos-duration="500"
            data-aos-once="true"
          />

          <Box
            bgcolor="primary.light"
            position="absolute"
            right="0"
            sx={{
              background:
                "linear-gradient(180deg, rgba(227,89,32,1) 0%, rgba(240,128,32,1) 69%, rgba(249,155,32,1) 100%)",
              borderTopLeftRadius: 125,
              borderBottomLeftRadius: 125,
              height: {
                xs: "175px",
                sm: "200px",
                md: "250px",
              },
              width: {
                xs: "87.5px",
                sm: "100px",
                md: "125px",
              },
            }}
            data-aos="fade-left"
            data-aos-delay={500}
            data-aos-duration="500"
            data-aos-once="true"
          />
        </Box>
        <Box
          marginTop={6}
          px={5}
          data-aos="fade-right"
          data-aos-delay={500}
          data-aos-duration="500"
          data-aos-once="true"
        >
          <Typography component="h1" variant="h2" fontWeight={700}>
            <b>
              {t__(theme.lang, caribbean, "dontForget")}

              <span
                style={{ display: "block", color: theme.palette.primary.light }}
              >
                {t__(theme.lang, caribbean, "discoverCard")}
              </span>
            </b>
          </Typography>
          <Typography
            fontSize="16px"
            component="p"
            marginTop={3}
            marginBottom={4}
          >
            {t__(theme.lang, caribbean, "useEverywhere")}
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="flex-start"
          px={5}
          data-aos="fade-right"
          data-aos-delay={500}
          data-aos-duration="500"
          data-aos-once="true"
        >
          <Box>
            <img src={dgnLogo} alt="" width={77} height={48} />
          </Box>
          <Box ml={5}>
            <img src={dinersLogo} alt="" width={65} height={48} />
          </Box>
        </Box>
      </Box>
      <Container
        style={{ background: "transparent", position: "relative", zIndex: 1 }}
        display={{ xs: "none", md: "block" }}
      >
        <Box
          color="common.white"
          display="flex"
          height={{ md: 300, xl: 400 }}
          paddingRight={{ md: "56%", lg: "30%" }}
          marginX="auto"
          flexDirection="column"
          justifyContent="center"
          data-aos="fade-right"
          data-aos-delay={500}
          data-aos-duration="500"
          data-aos-once="true"
        >
          <Typography
            fontSize={{ md: "36px", lg: "42px" }}
            fontWeight={700}
            lineHeight={1.1}
            component="h1"
          >
            {t__(theme.lang, caribbean, "dontForget")}
            <span
              style={{
                display: "block",
                color: theme.palette.primary.light,
              }}
            >
              {t__(theme.lang, caribbean, "discoverCard")}
            </span>
          </Typography>
          <Typography
            fontSize="16px"
            component="p"
            marginTop={3}
            marginBottom={6}
          >
            {t__(theme.lang, caribbean, "useEverywhere")}
          </Typography>
          <Box display="flex" justifyContent="flex-start">
            <Box>
              <img src={dgnLogo} alt="" width={77} height={48} />
            </Box>
            <Box ml={5}>
              <img src={dinersLogo} alt="" width={65} height={48} />
            </Box>
          </Box>
        </Box>
      </Container>
      <Box
        display={{ xs: "none", md: "flex" }}
        width="100%"
        position="absolute"
        top={60}
        height={{ md: 300, xl: 400 }}
      >
        <Box
          sx={{
            height: { md: 300, xl: 400 },
            width: {
              md: `calc(100% - 150px)`,
              xl: `calc(100% - 200px)`,
              xxl: "72%",
            },
            borderTopRightRadius: { md: 150, xl: 200 },
            borderBottomRightRadius: { md: 150, xl: 200 },
            overflow: "hidden",
            backgroundColor: "primary.main",
            position: "relative",
          }}
        >
          <Box
            sx={{
              height: { md: 300, xl: 400 },
              width: { md: 300, xl: 400 },
              backgroundColor: "#fff",
              borderRadius: { md: 300, xl: 400 },
              overflow: "hidden",
              background: "url(/caribbean_marquee_hero.jpg) center no-repeat",
              backgroundSize: "cover",
              position: "absolute",
              top: 0,
              right: 0,
            }}
            data-aos="flip-right"
            data-aos-delay={500}
            data-aos-duration="500"
            data-aos-once="true"
          />
        </Box>
        <Box
          bgcolor="primary.light"
          width={{ md: 150, xl: 200, xxl: 400 }}
          height={{ md: 300, xl: 400, xxl: 400 }}
          position={{ md: "absolute", xxl: "relative" }}
          display={{ xxl: "none" }}
          right={0}
          sx={{
            background:
              "linear-gradient(180deg, rgba(227,89,32,1) 0%, rgba(240,128,32,1) 69%, rgba(249,155,32,1) 100%)",
            borderTopLeftRadius: { md: 150, xl: 200, xxl: 200 },
            borderBottomLeftRadius: { md: 150, xl: 200, xxl: 200 },
            borderTopRightRadius: { xxl: 200 },
            borderBottomRightRadius: { xxl: 200 },
          }}
        />
        <Box
          display={{ md: "none", xxl: "block" }}
          bgcolor="background.light"
          height={400}
          width="28%"
          position="relative"
          sx={{
            borderTopLeftRadius: 200,
            borderBottomLeftRadius: 200,
          }}
        >
          <Box
            bgcolor="primary.light"
            width={400}
            height={400}
            position="absolute"
            left="0"
            sx={{
              background:
                "linear-gradient(180deg, rgba(227,89,32,1) 0%, rgba(240,128,32,1) 69%, rgba(249,155,32,1) 100%)",
              borderTopLeftRadius: { md: 150, xl: 200, xxl: 200 },
              borderBottomLeftRadius: { md: 150, xl: 200, xxl: 200 },
              borderTopRightRadius: { xxl: 200 },
              borderBottomRightRadius: { xxl: 200 },
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default Marquee;
