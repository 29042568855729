import { Box, Button, Typography, useTheme } from "@mui/material/";
import dgnLogo from "../Icons/DGN_AcceptanceMark_FC_Hrz_RGB.png";
import dinersLogo from "../Icons/asset-decal-diner@2x.png";
import Container from "../Layout/Container";
import isDefaultBrand from "../../util/isDefaultBrand";
import t__ from "../../util/lang/util";
import caribbean from "../../util/lang/caribbean";

const AcceptedAt = () => {
  const theme = useTheme();
  return (
    <Box
      width="100%"
      bgcolor="secondary.light"
      color="common.white"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      py={20}
    >
      <Container bgcolor="secondary.light">
        <Box display="flex" justifyContent="center">
          <Box>
            <img src={dgnLogo} alt="" width={103} height={64} />
          </Box>
          <Box ml={5}>
            <img src={dinersLogo} alt="" width={87} height={64} />
          </Box>
        </Box>
        <Typography
          variant="h2"
          align="center"
          my={{ xs: 5, md: 6 }}
          component="h3"
        >
          <b>Accepted at millions of places around the world</b>
        </Typography>
        <Box display="flex" justifyContent="center">
          <Button
            sx={{
              fontSize: "19px",
              fontWeight: "bold",
              color: `${theme.palette.common.white} !important`,
              textTransform: "none",
              height: 44,
              padding: "0 24px",
              borderRadius: 20,
              backgroundColor: `${
                isDefaultBrand(theme)
                  ? theme.palette.primary.light
                  : theme.palette.primary.main
              }`,
              "&:hover, &:focus": {
                backgroundColor: `${
                  isDefaultBrand(theme)
                    ? "#E94D00"
                    : theme.palette.brand.primaryBg
                }`,
              },
            }}
            href={`/guides/${
              !isDefaultBrand(theme) ? `?brand=${theme.brand}` : ``
            }`}
          >
            {t__(theme.lang, caribbean, `acceptancecallOut`)}
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default AcceptedAt;
