const t__ = (lang = "en", file, copy) => {
  const getValue = (object, keys) =>
    keys.split(".").reduce((o, k) => (o || {})[k], object);

  let text = getValue(file[lang], copy);
  if (!text) {
    text = getValue(file["en"], copy);
  }
  return text;
};

export default t__;
