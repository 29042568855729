import theme from "./theme/default";
import elo from "./theme/elo";
import troy from "./theme/troy";
import cabal from "./theme/cabal";

const themes = {
  default: theme,
  elo: elo,
  troy: troy,
  cabal: cabal,
};

export default themes;
