import { getCountries } from "/";
const { useQuery } = require("react-query");

function useCountries() {
  return useQuery(
    "countries",
    async () => {
      const response = await getCountries();
      if (response.ok) {
        return await response.json();
      } else {
        throw new Error(response.status);
      }
    },
    { refetchOnWindowFocus: false } // this data doesnt update often
  );
}

export default useCountries;
