import React from "react";
import PropTypes from "prop-types";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material/";
import t__ from "../../util/lang/util";
//import AcceptanceMarks from "../AcceptanceMarks";
import countryDrawer from "../../util/lang/countryDrawer";
import caribbean from "../../util/lang/caribbean";
import DN from "../Icons/discover-card@3x.webp";
import DCI from "../Icons/diners-club-international-card@3x.webp";
import ELO from "../Icons/elo-card@3x.webp";
import RuPay from "../Icons/rupay-card@3x.webp";
import Troy from "../Icons/troy-card@3x.webp";
import DinaCard from "../Icons/dinacard-card@3x.webp";
import cabal from "../Icons/verve-card@3x.webp";
import BC from "../Icons/bc-card@3x.webp";
import Mercury from "../Icons/mercury.png";
import dgnLogo from "../Icons/DGN_AcceptanceMark_FC_Hrz_RGB.png";
import dinersLogo from "../Icons/asset-decal-diner@2x.png";

const cards = [
  {
    img: DN,
    card: "Discover®",
  },
  {
    img: DCI,
    card: "Diners Club International®",
  },
  {
    img: Troy,
    card: "Troy",
  },
  {
    img: cabal,
    card: "Cabal",
  },
  {
    img: RuPay,
    card: "RuPay",
  },
  {
    img: BC,
    card: "BC Card",
  },
  {
    img: DinaCard,
    card: "DinaCard",
  },
  {
    img: ELO,
    card: "Elo",
  },
  {
    img: Mercury,
    card: "Mercury",
  },
];

const AllCardsBox = ({ countryData, hasCreditCoverage, region, inDrawer }) => {
  const theme = useTheme();
  const mdScreen = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box
      border={inDrawer ? 0 : 1}
      borderColor={inDrawer ? "none" : "borders.light"}
      borderRadius={inDrawer ? 0 : theme.spacing(3)}
      padding={
        inDrawer
          ? theme.spacing(0)
          : mdScreen
          ? theme.spacing(10)
          : theme.spacing(5)
      }
      width="100%"
    >
      {inDrawer ? (
        <>
          <Typography variant="body1">
            <b>{t__(theme.lang, caribbean, "lookFor")}</b>
          </Typography>
          <Box display="flex" justifyContent="flex-start" marginTop={4}>
            <Box>
              <img src={dgnLogo} alt="" width={77} height={48} />
            </Box>
            <Box ml={5}>
              <img src={dinersLogo} alt="" width={65} height={48} />
            </Box>
          </Box>
        </>
      ) : (
        <Typography variant={mdScreen ? "h3" : "body1"}>
          <b>
            {hasCreditCoverage
              ? `${t__(
                  theme.lang,
                  countryDrawer,
                  "acceptanceMarkHeadline.coverage"
                )} ${countryData.cca3 === "USA" ? "the" : ""} ${
                  countryData.common_name
                }`
              : t__(
                  theme.lang,
                  countryDrawer,
                  `acceptanceMarkHeadline.noCoverage`
                )}
          </b>
        </Typography>
      )}

      <Box mt={3} mb={3}>
        <Typography>
          {t__(
            theme.lang,
            inDrawer ? caribbean : countryDrawer,
            `${
              hasCreditCoverage
                ? "acceptanceATMcoverage"
                : `noAcceptanceATMcoverage.${region}`
            }`
          )}
        </Typography>
      </Box>

      <ul
        style={{
          display: "flex",
          flexWrap: "wrap",
          listStyle: "none",
          margin: 0,
          padding: 0,
          marginTop: theme.spacing(3),
          marginLeft: -8,
        }}
      >
        {cards.map((c) => (
          <li
            style={{
              margin: "10px 6px 0 0",
              padding: 0,
              width: mdScreen ? "30%" : "45%",
            }}
            key={c.card}
          >
            <img src={c.img} alt={c.card} width="100%" />
            <Typography
              variant="h5"
              sx={{ fontWeight: 700, paddingLeft: 2, marginTop: "-10px" }}
            >
              {c.card}
            </Typography>
          </li>
        ))}
      </ul>
    </Box>
  );
};

AllCardsBox.propTypes = {
  countryData: PropTypes.object.isRequired,
  hasCreditCoverage: PropTypes.bool.isRequired,
  region: PropTypes.oneOf([
    "Europe",
    "Africa",
    "Asia",
    "America",
    "Antarctica",
  ]),
};

export default AllCardsBox;
