import { ReactComponent as BedIcon } from "../components/Icons/bed.svg";
import { ReactComponent as EatIcon } from "../components/Icons/eat.svg";
import { ReactComponent as EnjoyIcon } from "../components/Icons/enjoy.svg";
import { ReactComponent as GoIcon } from "../components/Icons/go.svg";
import { ReactComponent as ShopIcon } from "../components/Icons/shop.svg";

const CATEGORIES = [
  {
    title: "Eat",
    icon: <EatIcon />,
    mcc: "restaurants",
  },
  {
    title: "Shop",
    icon: <ShopIcon />,
    mcc: "retail",
  },
  {
    title: "Stay",
    icon: <BedIcon />,
    mcc: "hotels",
  },
  {
    title: "Attractions",
    icon: <EnjoyIcon />,
    mcc: "attractions",
  },
  {
    title: "Attractions",
    icon: <EnjoyIcon />,
    mcc: "top_attractions",
  },
  {
    title: "Transportation",
    icon: <GoIcon />,
    mcc: "transportation",
  },
  {
    title: "All",
    icon: null,
    mcc: "all",
  },
];

export default CATEGORIES;
