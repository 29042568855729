/* eslint-disable import/no-anonymous-default-export */
// https://material-ui.com/customization/palette/
import defaultPalette from "../default/palette";
import circleELO from "../../../../src/components/Icons/logo-cabal@3x.png";

export const palette = {
  ...defaultPalette.palette,
  brand: {
    primaryBg: "#0075bf", //intro page top marquee background color
    introMarqueeBg: "#00538b", //intro page top marquee
    guideSelectBg: "#00538b", //intro page dropdown background color
    lightHeaderCircle: "#169FDB", // half circle bg that appears at the top
    map: "#00538b",
    text: "#FFF",
    icon: circleELO,
    footerBg: "#00538b",
    bannerTilesBg: "#0075bf", //guide page
    filterButtonsColor: "#63686b",
    filterButtonActive: "#00538b",
    modalLegendText: "#5a5858",
    searchFlyoutHeader: "#00538b",
  },
  text: {
    primary: "#5a5858",
    secondary: "#5a5858",
    light: "#ffffff",
  },
  primary: {
    main: "#00538b",
  },
  secondary: {
    main: "#00538b",
  },
};

export default {
  palette,
};
