import { MAPCOLORS } from "./mapstyles";
import "../../util/theme/flags.css";
import acceptanceLevels from "../../util/lang/acceptanceLevels";
import isTouchDevice from "../../util/isTouchDevice";
import isDefaultBrand from "../../util/isDefaultBrand";
import adjustColor from "../../util/adjustColor";
import trackLocationChange from "../../util/trackLocationChange";

export const assignAcceptanceMapData = (map, acceptanceData) => {
  if (!map) return;
  map.setMapTypeId("acceptance_map");
  map.data.forEach(function (feature) {
    const foundCountry = acceptanceData.find(
      (d) => d.cca3 === feature.getProperty("adm0_a3")
    );
    const credit_level = foundCountry?.credit_level ?? "NO COVERAGE";
    const translated_name = foundCountry?.translated_name ?? "";
    feature.setProperty("credit_level", credit_level);
    feature.setProperty("isActive", false);
    feature.setProperty("translated_name", translated_name);
  });
};

export const getInitialZoom = () => {
  const windowWidth = window.innerWidth;
  if (windowWidth > 2048) {
    return 4;
  } else if (windowWidth > 1024) {
    return 3;
  } else if (windowWidth > 512) {
    return 2;
  } else {
    return 2;
  }
};

export const makeCountryMap = (map, countryMapType, theme) => {
  const mapColors = isDefaultBrand(theme)
    ? MAPCOLORS.default
    : {
        "NO COVERAGE": "#f2f2f2",
        J: adjustColor(theme.palette.brand.map, 0.2),
        G: adjustColor(theme.palette.brand.map, 0.45),
        H: theme.palette.brand.map,
      };
  map.mapTypes.set("acceptance_map", countryMapType);
  map.data.setStyle((feature) => {
    const credit_level = feature.getProperty("credit_level");
    const styleProps = {
      fillOpacity: 1,
      fillColor: mapColors[credit_level],
      strokeColor: "#63686B",
      strokeWeight: 0.25,
    };
    return styleProps;
  });
};

export const makeCityMap = (map, cityMapType) => {
  map.mapTypes.set("acceptance_map", cityMapType);
  map.data.setStyle(() => {
    const styleProps = {
      fillColor: "transparent",
      strokeColor: "transparent",
    };
    return styleProps;
  });
};

const processPoints = (geometry, callback, thisArg) => {
  if (geometry instanceof window.google.maps.LatLng) {
    callback.call(thisArg, geometry);
  } else if (geometry instanceof window.google.maps.Data.Point) {
    callback.call(thisArg, geometry.get());
  } else {
    geometry.getArray().forEach(function (g) {
      processPoints(g, callback, thisArg);
    });
  }
};

//these variables keep track of the different windows on click and hover for the accc
let areaInfoWindow = null;
let clickedAreaInfoWindow = null;
let activeFeature = null;
let hovered = false;
let cityView = false;

export const getTooltipContent = (feature, showAcceptanceCopy, theme) => {
  return `
      <div class="acceptance-infowindow ${
        showAcceptanceCopy && "large"
      }" style="font-family: ${theme.typography.fontFamily}; color: ${
    theme.palette.text.primary
  }" >
        <div class="headline"><div class="flag:${feature.getProperty(
          "iso_a2"
        )}"></div>
        <b>${
          feature.getProperty("translated_name") ?? feature.getProperty("name")
        }</b></div>
        <p style="color: ${theme.palette.text.secondary}; display: ${
    showAcceptanceCopy ? "block" : "none"
  }">${
    acceptanceLevels[theme.lang].tooltip[feature.getProperty("credit_level")]
  }</p>
      </div>
      `;
};

export const countryView_addListeners = (
  map,
  cityPins,
  theme,
  setSelectedCountry,
  setCityView,
  setMapLoading,
  setMerchants,
  setFilteredMerchants,
  history,
  activeTooltip
) => {
  map.addListener("click", function () {
    if (cityView) return false;
    if (clickedAreaInfoWindow != null) {
      clickedAreaInfoWindow.close();
      clickedAreaInfoWindow = null;
      if (activeFeature) {
        activeFeature.setProperty("isActive", false);
      }
      setSelectedCountry(null);
      activeTooltip.current = null;
    }
    map.setZoom(map.minZoom);
  });

  map.data.addListener("click", function (e) {
    if (cityView) return false;
    countryClicked(map, setSelectedCountry, activeTooltip, theme, e);
  });

  if (!isTouchDevice()) {
    map.data.addListener("mousemove", function (e) {
      if (cityView) return false;
      if (hovered) {
        areaInfoWindow?.close();
        return;
      }
      if (areaInfoWindow != null) {
        areaInfoWindow.close();
      }
      if (e.feature.getProperty("isActive")) {
        areaInfoWindow?.close();
        return;
      }
      let bounds = new window.google.maps.LatLngBounds();
      processPoints(e.feature.getGeometry(), bounds.extend, bounds);
      areaInfoWindow = new window.google.maps.InfoWindow({
        content: getTooltipContent(e.feature, false, theme),
        pixelOffset: new window.google.maps.Size(0, -5),
      });

      areaInfoWindow.setPosition(e.latLng);
      areaInfoWindow.open(map);
    });

    if (!isTouchDevice()) {
      map.data.addListener("mouseout", function () {
        if (areaInfoWindow != null) {
          areaInfoWindow.close();
          areaInfoWindow = null;
        }
      });
    }
  }

  cityPins.forEach((c) => {
    window.google.maps.event.addListener(c, "click", function (e) {
      window.scrollTo(0, 0);
      setMapLoading(true);
      setMerchants(null);
      setFilteredMerchants(null);
      setCityView(c.merchantData.city);

      isDefaultBrand(theme)
        ? history.push(`/guides/${c.merchantData.route}`)
        : history.push(`/guides/${c.merchantData.route}?brand=${theme.brand}`);
      trackLocationChange();
    });
    if (!isTouchDevice()) {
      window.google.maps.event.addListener(c, "mouseover", function () {
        hovered = true;
        c.set("labelClass", "hovered");
      });
      window.google.maps.event.addListener(c, "mouseout", function () {
        hovered = false;
        c.set("labelClass", "");
      });
    }
  });
};

export function countryClicked(
  map,
  setSelectedCountry,
  activeTooltip,
  theme,
  e,
  feat
) {
  //if (cityView) return false; //not sure what we are doing here
  //if clicking into a new country repostion the map and pan
  const feature = e ? e.feature : feat;
  let bounds = new window.google.maps.LatLngBounds();
  processPoints(feature.getGeometry(), bounds.extend, bounds);

  if (areaInfoWindow != null) {
    areaInfoWindow.close();
    activeTooltip.current = null;
  }

  if (!feature.getProperty("isActive")) {
    //https://stackoverflow.com/questions/3817812/google-maps-v3-can-i-ensure-smooth-panning-every-time
    var panPath = []; // An array of points the current panning action will use
    var panQueue = []; // An array of subsequent panTo actions to take
    var STEPS = 5; // The number of steps that each panTo action will undergo

    function panTo(newLat, newLng) {
      if (panPath.length > 0) {
        // We are already panning...queue this up for next move
        panQueue.push([newLat, newLng]);
      } else {
        // Lets compute the points we'll use
        panPath.push("LAZY SYNCRONIZED LOCK"); // make length non-zero - 'release' this before calling setTimeout
        var curLat = map.getCenter().lat();
        var curLng = map.getCenter().lng();
        var dLat = (newLat - curLat) / STEPS;
        var dLng = (newLng - curLng) / STEPS;

        for (var i = 0; i < STEPS; i++) {
          panPath.push([curLat + dLat * i, curLng + dLng * i]);
        }
        panPath.push([newLat, newLng]);
        panPath.shift(); // LAZY SYNCRONIZED LOCK
        setTimeout(doPan, 20);
      }
    }

    function doPan() {
      var next = panPath.shift();
      if (next != null) {
        // Continue our current pan action
        map.panTo(new window.google.maps.LatLng(next[0], next[1]));
        setTimeout(doPan, 20);
      } else {
        // We are finished with this pan - check if there are any queue'd up locations to pan to
        var queued = panQueue.shift();
        if (queued != null) {
          panTo(queued[0], queued[1]);
        }
      }
    }
    //figure out if bounds are in view in here too - LATER REFINEMENT IF TIME
    const center = bounds.getCenter();
    panTo(center.lat(), center.lng());
  }

  map.data.revertStyle();
  if (activeFeature) {
    activeFeature.setProperty("isActive", false);
  }
  feature.setProperty("isActive", true);
  activeFeature = feature;

  if (clickedAreaInfoWindow != null) {
    clickedAreaInfoWindow.close();
    clickedAreaInfoWindow = null;
  }

  clickedAreaInfoWindow = new window.google.maps.InfoWindow({
    content: getTooltipContent(feature, true, theme),
  });
  clickedAreaInfoWindow.setPosition(e ? e.latLng : bounds.getCenter());
  clickedAreaInfoWindow.open(map);
  activeTooltip.current = clickedAreaInfoWindow;

  setSelectedCountry({
    code: feature.getProperty("adm0_a3"),
    region: feature.getProperty("region_wb"),
  });
}

export const countryView_pauseEvents = (bool) => {
  cityView = bool;
};

export const createCustomZoomControl = (map, google) => {
  var zoomControlDiv = document.createElement("div");
  var zoomControl = new ZoomControl(zoomControlDiv, map);

  function ZoomControl(controlDiv, map) {
    //is there a better way to do this?
    const controlWrapper = document.createElement("div");
    const zoomOutButton = document.createElement("button");
    const zoomInButton = document.createElement("button");

    zoomControlDiv.classList.add("zoom-control");
    zoomInButton.innerHTML = "&plus;";
    zoomOutButton.innerHTML = "&minus;";
    controlWrapper.appendChild(zoomInButton);
    controlWrapper.appendChild(zoomOutButton);
    controlDiv.appendChild(controlWrapper);

    google.maps.event.addDomListener(zoomInButton, "click", function () {
      map.setZoom(map.getZoom() + 1);
    });
    google.maps.event.addDomListener(zoomOutButton, "click", function () {
      map.setZoom(map.getZoom() - 1);
    });
  }
  zoomControlDiv.index = 1;
  map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(zoomControlDiv);
  return zoomControl;
};

export const haversine_distance = (mk1, mk2) => {
  var R = 3958.8; // Radius of the Earth in miles
  var rlat1 = mk1.position.lat * (Math.PI / 180); // Convert degrees to radians
  var rlat2 = mk2.position.lat * (Math.PI / 180); // Convert degrees to radians
  var difflat = rlat2 - rlat1; // Radian difference (latitudes)
  var difflon = (mk2.position.lng - mk1.position.lng) * (Math.PI / 180); // Radian difference (longitudes)

  var d =
    2 *
    R *
    Math.asin(
      Math.sqrt(
        Math.sin(difflat / 2) * Math.sin(difflat / 2) +
          Math.cos(rlat1) *
            Math.cos(rlat2) *
            Math.sin(difflon / 2) *
            Math.sin(difflon / 2)
      )
    );
  return d;
};

export async function getMerchantContent(id, map, language) {
  try {
    const service = new window.google.maps.places.PlacesService(map);

    var request = {
      placeId: id,
      language: language ?? "en",
      fields: [
        "opening_hours",
        "photos",
        "place_id",
        "price_level",
        "rating",
        "reviews",
        "types",
        "url",
        "user_ratings_total",
        "utc_offset_minutes",
        "business_status",
        "formatted_phone_number",
        "website",
        "address_components",
        "formatted_address",
        "name",
      ],
    };

    const { results, status } = await new Promise((resolve) =>
      service.getDetails(request, (results, status) =>
        resolve({ results, status })
      )
    );

    if (status === "OVER_QUERY_LIMIT") {
      return id;
    }

    if (status === "OK") {
      return results;
    }
  } catch (e) {
    console.log(e);
  }
}
