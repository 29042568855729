import { useState, useEffect, useRef } from "react";
import { Loader } from "@googlemaps/js-api-loader";
import { useTheme } from "@mui/material/";

export function useGoogleMapsApi(apiKey, libraries = []) {
  const [googleApi, setGoogleApi] = useState(null);
  const [loadError, setLoadError] = useState();
  const prevLibraries = useRef();
  const theme = useTheme();

  useEffect(() => {
    if (googleApi) {
      return;
    }

    const loader = new Loader({
      id: "default",
      apiKey,
      libraries,
      language: theme.lang ?? "en",
    });

    loader
      .load()
      .then(() => {
        setGoogleApi(window.google);
      })
      .catch((error) => {
        setLoadError(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiKey, libraries]);

  useEffect(() => {
    if (prevLibraries.current && libraries !== prevLibraries.current) {
      console.warn(
        "Performance warning! LoadScript has been reloaded unintentionally! You should not pass `libraries` prop as new array. Please keep an array of libraries as static class property for Components and PureComponents, or just a const variable outside of component, or somewhere in config files or ENV variables"
      );
    }
    prevLibraries.current = libraries;
  }, [libraries]);

  return [googleApi, loadError];
}
