import { Box, useTheme } from "@mui/material/";
import { styled } from '@mui/material/styles';
import PropTypes from "prop-types";
import Skeleton from "@mui/material/Skeleton";
import CATEGORIES from "../../../util/categories";
import isDefaultBrand from "../../../util/isDefaultBrand";

const PREFIX = 'MerchantImage';

const classes = {
  imagecover: `${PREFIX}-imagecover`,
  placeholder: `${PREFIX}-placeholder`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.imagecover}`]: {
    position: "absolute",
    left: "-10000%",
    right: "-10000%",
    top: "-10000%",
    bottom: "-10000%",
    margin: "auto auto",
    minWidth: "1000%",
    minHeight: "1000%",
    "-webkit-transform": "scale(0.1)",
    transform: "scale(0.1)",
  },

  [`& .${classes.placeholder}`]: {
    color: theme.palette.common.white,
    backdropFilter: "blur(4px)",
    background: "rgba(255, 255, 255, 0.5)",
    height: 240,
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    borderBottomRightRadius: "120px",
    "& svg": {
      transform: "scale(5)",
      position: "absolute",
      top: "100px",
    },
  }
}));

const MerchantImage = ({ drawer, googleData, loading }) => {
  const theme = useTheme();

  return (
    (<Root>
      {loading ? (
        <Box height="240px" width="100%">
          <Skeleton
            height="240px"
            width="calc(100% - 40px)"
            style={{
              transform: "none",
              margin: theme.spacing(5),
              marginTop: 0,
            }}
          />
        </Box>
      ) : (
        <>
          {!googleData[drawer.google_place_id]?.photos ? (
            <Box bgcolor="secondary.light" position="relative">
              <Box
                height="240px"
                width="100%"
                style={{
                  background: `url(/PlaceholderImages/${CATEGORIES.find(
                    (c) => c.mcc === googleData[drawer.google_place_id].mcc
                  )?.title.toLowerCase()}/photo${Math.floor(
                    Math.random() * 3 + 1
                  )}.jpg)`,
                  backgroundSize: "cover",
                  borderBottomRightRadius: "120px",
                }}
              ></Box>
              <Box
                className={classes.placeholder}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                {
                  CATEGORIES.find(
                    (c) => c.mcc === googleData[drawer.google_place_id].mcc
                  )?.icon
                }
              </Box>
            </Box>
          ) : (
            <Box
              bgcolor={
                isDefaultBrand(theme)
                  ? "secondary.light"
                  : theme.palette.brand.primaryBg
              }
            >
              <Box
                height="240px"
                width="100%"
                style={{
                  borderBottomRightRadius: "120px",
                  overflow: "hidden",
                  position: "relative",
                }}
              >
                <img
                  src={googleData[drawer.google_place_id].photos[0].getUrl()}
                  className={classes.imagecover}
                  alt=""
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = `/PlaceholderImages/${CATEGORIES.find(
                      (c) => c.mcc === googleData[drawer.google_place_id].mcc
                    )?.title.toLowerCase()}/photo${Math.floor(
                      Math.random() * 3 + 1
                    )}.jpg`;
                  }}
                />
              </Box>
            </Box>
          )}
        </>
      )}
    </Root>)
  );
};

MerchantImage.propTypes = {
  drawer: PropTypes.object,
  googleData: PropTypes.object,
  loading: PropTypes.bool.isRequired,
};

export default MerchantImage;
