import { palette } from "../palette";

export default {
  MuiCssBaseline: {
    styleOverrides: {
      body: {
        fontSize: 16,
      },
      ".no-focus-outline": {
        outlineStyle: "none",
      },
      "a, a:visited, a:active": {
        color: `${palette.secondary.main}`,
      },
      ".MuiBackdrop-root": {
        backgroundColor: "rgba(0, 0, 0, 0.3)",
      },
    },
  },
};
