import React from "react";
import { Box, Typography, useTheme, Link } from "@mui/material/";
import t__ from "../../util/lang/util";
import common from "../../util/lang/common";
import TechDiffIcon from "../Icons/group-2.svg";
import Container from "../Layout/Container";
import AcceptanceMarks from "../AcceptanceMarks";

const TechDiff = () => {
  const theme = useTheme();
  return (
    <Box bgcolor="background.light" height={"100vh"}>
      <Box
        bgcolor="common.white"
        height={62}
        display="flex"
        alignItems="center"
      >
        <Container>
          <Link href="/">
            <AcceptanceMarks variant="xs" />
          </Link>
        </Container>
      </Box>
      <Box bgcolor="background.light">
        <Container bgcolor="background.light">
          <Box
            position="absolute"
            top={"calc(50vh - 62px)"}
            width="100%"
            left="0"
            align="center"
          >
            <img src={TechDiffIcon} alt="" />
            <Box marginY={5}>
              <Typography variant="subtitle2">
                <b>{t__(theme.lang, common, "techDiff")}</b>
              </Typography>
            </Box>
            <Typography>
              {t__(theme.lang, common, "techDiffMessage")}
            </Typography>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default TechDiff;
