const countryDrawer = {
  en: {
    priorityCountries: {
      USA: "Your Discover® Card is accepted by 99% of the places across the country that take credit cards*. Look for these logos at checkout, so you know your card is accepted:",
      JPN: "You can use your card at many locations here, including many top merchants in Tokyo, Osaka, and Kyoto. Look for these logos at checkout, so you know your card is accepted:",
      PER: "You can use your card at many locations here, including many top merchants in Lima. Look for these logos at checkout, so you know your card is accepted:",
      ESP: "You can use your card at many locations here, including many top merchants in Barcelona, Madrid, and Seville. Look for these logos at checkout, so you know your card is accepted:",
      URY: "You can use your card at many locations here, including many top merchants in Montevideo, Punta del Este, and Colonia del Sacramento. Look for these logos at checkout, so you know your card is accepted:",
      ARG: "You can use your card at a growing number of locations here, including many top merchants in Buenos Aires and Mendoza. Look for these logos at checkout, so you know your card is accepted:",
      CHL: "You can use your card at a growing number of locations here, including many top merchants in Santiago. Look for these logos at checkout, so you know your card is accepted:",
      DEU: "You can use your card at many locations here, including many top merchants in Berlin. Look for these logos at checkout, so you know your card is accepted:",
      MEX: "You can use your card at a growing number of locations here, including many top merchants in Cancun. Look for these logos at checkout, so you know your card is accepted:",
      GBR: "You can use your card at many locations here, including many top merchants in London. Look for these logos at checkout, so you know your card is accepted:",
      CAN: "You can use your card at many locations here, including many top merchants in Vancouver and Toronto. Look for these logos at checkout, so you know your card is accepted:",
      ITA: "You can use your card at a growing number of locations here, including many top merchants in Rome. Look for these logos at checkout, so you know your card is accepted:",
      PRT: "You can use your card at a growing number of locations here, including many top merchants in Lisbon. Look for these logos at checkout, so you know your card is accepted:",
      AUS: "You can use your card at a growing number of locations here, including many top merchants in Sydney, Melbourne, and Brisbane. Look for these logos at checkout, so you know your card is accepted:",
      FRA: "You can use your card at a growing number of locations here, including many top merchants in Paris. Look for these logos at checkout, so you know your card is accepted:",
    },
    acceptanceMarkHeadline: {
      coverage: "Have one of these cards? You can use it in",
      noCoverage: "Have one of these cards? Look for acceptance in this region",
    },
    aboveMarksCopy: {
      coverage: "What to look for when you pay in ",
      noCoverage: {
        Europe: "What to look for when you pay in Europe",
        Africa: "In-network Payments in the Middle East & Africa",
        Asia: "In-network Payments in Asia Pacific",
        America: "What to look for when you pay in the Americas",
      },
    },
    acceptanceATMcoverage:
      "Because you're a part of the Discover Global Network, you can travel with confidence.",
    noAcceptanceATMcoverage: {
      Europe:
        "Because you’re a part of the Discover Global Network, you can use your card across some of Europe's most popular destinations, including Spain, Germany, the UK, Italy, Portugal and France.",
      Africa:
        "Because you’re a part of the Discover Global Network, you can use your card in the UAE, Egypt, Morocco and South Africa. With destinations in the Middle East & Africa expanding rapidly, you can use your card in more and more places here.",
      Asia: "Because you’re a part of the Discover Global Network, you can use your card almost everywhere in Japan as well as top travel destinations across Asia and Pacific Islands, including India, China, Singapore, Australia and more.",
      America:
        "Because you’re a part of the Discover Global Network, you can use your card across Canada, Mexico and the U.S., as well as in popular South American travel spots like Peru, Uruguay, Argentina, Chile and more.",
      Antarctica:
        "Because you’re a part of the Discover Global Network, you can use your card across Canada, Mexico and the U.S., as well as in popular South American travel spots like Peru, Uruguay, Argentina, Chile and more.",
    },
    regions: {
      Europe: "Europe",
      Africa: "the Middle East & Africa",
      Asia: "Asia Pacific",
      America: "the Americas",
    },
    atm: {
      coverage: {
        headline: "Need cash while you’re here?",
        subtitle: "Use your card to get local currency at these ATMs:",
        supplmentary:
          "Plus, any ATM displaying the Discover Network, Diners Club International, or PULSE logo.",
        fees: "Fees and limits may apply. PIN may also be required.",
      },
      coverageAlt: {
        headline: "You can use your card at these ATMs",
        subtitle: "Use your card to get local currency at these ATMs:",
        supplmentary:
          "Plus, any ATM displaying the Discover Network, Diners Club International, or PULSE logo.",
        fees: "Fees and limits may apply. PIN may also be required.",
      },
      noCoverage: {
        headline: "ATM coverage is emerging",
        subtitle: "We are working to establish ATM acceptance in this country.",
      },
    },
  },
  es: {
    priorityCountries: {
      USA: "Su tarjeta Discover® es aceptada por el 99% de los lugares en todo el país que aceptan tarjetas de crédito.* Busca estos logotipos en el momento de pagar para saber si tu tarjeta es aceptada:",
      JPN: "Puedes usar tu tarjeta en muchos lugares aquí, incluyendo muchos de los principales comercios en Tokio, Osaka y Kioto. Busca estos logotipos en el momento de pagar para saber si tu tarjeta es aceptada:",
      PER: "Puedes usar tu tarjeta en muchos lugares aquí, incluyendo muchos de los principales comercios en Lima. Busca estos logotipos en el momento de pagar para saber si tu tarjeta es aceptada:",
      ESP: "Puedes usar tu tarjeta en muchos lugares aquí, incluyendo muchos de los principales comercios en Barcelona, Madrid y Sevilla. Busca estos logotipos en el momento de pagar para saber si tu tarjeta es aceptada:",
      URY: "Puedes usar tu tarjeta en muchos lugares aquí, incluyendo muchos de los principales comercios en Montevideo, Punta del Este y Colonia del Sacramento. Busca estos logotipos en el momento de pagar para saber si tu tarjeta es aceptada:",
      ARG: "Puedes usar tu tarjeta en un número creciente de lugares aquí, incluyendo muchos de los principales comercios en Buenos Aires y Mendoza. Busca estos logotipos en el momento de pagar para saber si tu tarjeta es aceptada:",
      CHL: "Puedes usar tu tarjeta en un número creciente de lugares aquí, incluyendo muchos de los principales comercios en Santiago. Busca estos logotipos en el momento de pagar para saber si tu tarjeta es aceptada:",
      DEU: "Puedes usar tu tarjeta en muchos lugares aquí, incluyendo muchos de los principales comercios en Berlin. Busca estos logotipos en el momento de pagar para saber si tu tarjeta es aceptada:",
      MEX: "Puedes usar tu tarjeta en un número creciente de lugares aquí, incluyendo muchos de los principales comercios en Cancún. Busca estos logotipos en el momento de pagar para saber si tu tarjeta es aceptada:",
      GBR: "Puedes usar tu tarjeta en muchos lugares aquí, incluyendo muchos de los principales comercios en London. Busca estos logotipos en el momento de pagar para saber si tu tarjeta es aceptada:",
      CAN: "Puedes usar tu tarjeta en un número creciente de lugares aquí, incluyendo muchos de los principales comercios en Vancouver y Toronto. Busca estos logotipos en el momento de pagar para saber si tu tarjeta es aceptada:",
      ITA: "Puedes usar tu tarjeta en un número creciente de lugares aquí, incluyendo muchos de los principales comercios en Roma. Busca estos logotipos en el momento de pagar para saber si tu tarjeta es aceptada:",
      PRT: "Puedes usar tu tarjeta en un número creciente de lugares aquí, incluyendo muchos de los principales comercios en Lisboa. Busca estos logotipos en el momento de pagar para saber si tu tarjeta es aceptada:",
      AUS: "Puedes usar tu tarjeta en un número creciente de lugares aquí, incluyendo muchos de los principales comercios en Sídney, Melbourne y Brisbane. Busca estos logotipos en el momento de pagar para saber si tu tarjeta es aceptada:",
      FRA: "Puedes usar tu tarjeta en un número creciente de lugares aquí, incluyendo muchos de los principales comercios en París. Busca estos logotipos en el momento de pagar para saber si tu tarjeta es aceptada:",
    },
    acceptanceMarkHeadline: {
      coverage: "¿Tienes una de estas tarjetas? Puedes usarla en",
      noCoverage:
        "¿Tienes una de estas tarjetas? Busca aceptación en esta región",
    },
    aboveMarksCopy: {
      coverage: "¿Qué buscar cuando paga en ",
      noCoverage: {
        Europe: "¿Qué buscar cuando paga en Europa?",
        Africa: "Pagos dentro de la red en Medio Oriente y África",
        Asia: "Pagos dentro de la red en Asia Pacífico",
        America: "¿Qué buscar cuando paga en América?",
      },
    },
    acceptanceATMcoverage:
      "Como eres parte de la Red Global Discover, puedes viajar con confianza.",
    noAcceptanceATMcoverage: {
      Europe:
        "Como eres parte de la Red Global Discover, puedes usar tu tarjeta en algunos de los destinos más populares de Europa, incluyendo España, Alemania, el Reino Unido, Italia, Portugal y Francia.",
      Africa:
        "Como eres parte de la Red Global Discover, puedes usar tu tarjeta en los Emiratos Árabes Unidos, Egipto, Marruecos y Sudáfrica. Con los destinos en Oriente Medio y África expandiéndose rápidamente, puedes usar tu tarjeta en cada vez más lugares aquí.",
      Asia: "Como eres parte de la Red Global Discover, puedes usar tu tarjeta casi en cualquier lugar de Japón, así como en los principales destinos de viaje en Asia y las Islas del Pacífico, incluyendo India, China, Singapur, Australia y más.",
      America:
        "Como eres parte de la Red Global Discover, puedes usar tu tarjeta en Canadá, México y los EE. UU., así como en destinos populares de América del Sur como Perú, Uruguay, Argentina, Chile y más.",
      Antarctica:
        "Como eres parte de la Red Global Discover, puedes usar tu tarjeta en Canadá, México y Estados Unidos, así como en destinos populares de América del Sur como Perú, Uruguay, Argentina, Chile y más.",
    },
    regions: {
      Europe: "Europa",
      Africa: "Medio Oriente y África",
      Asia: "Asia Pacífico",
      America: "América",
    },
    atm: {
      coverage: {
        headline: "¿Necesita dinero en efectivo mientras está aquí?",
        subtitle:
          "Use su tarjeta para obtener moneda local en estos cajeros automáticos:",
        supplmentary:
          "Además, cualquier cajero automático que muestre el logo de Discover Network, Diners Club International o PULSE.",
        fees: "Se pueden aplicar tarifas y límites. También puede ser necesario un PIN.",
      },
      coverageAlt: {
        headline: "Puede usar su tarjeta en estos cajeros automáticos",
        subtitle:
          "Use su tarjeta para obtener moneda local en estos cajeros automáticos:",
        supplmentary:
          "Además, cualquier cajero automático que muestre el logo de Discover Network, Diners Club International o PULSE.",
        fees: "Se pueden aplicar tarifas y límites. También puede ser necesario un PIN.",
      },
      noCoverage: {
        headline: "La cobertura de cajeros automáticos está creciendo",
        subtitle:
          "Estamos trabajando para establecer la aceptación en cajeros automáticos en este país.",
      },
    },
  },
};

export default countryDrawer;
