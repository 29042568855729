import React from "react";
import PropTypes from "prop-types";
import { Box, Typography, useTheme } from "@mui/material/";
import Rating from "@mui/material/Rating";
import t__ from "../../../util/lang/util";
import common from "../../../util/lang/common";
import StarIcon from "@mui/icons-material/Star";

const Ratings = ({
  data,
  hideOpenNow,
  hideNumber,
  textColor,
  variant,
  ...props
}) => {
  const theme = useTheme();

  return (
    <Box display="flex" justifyContent="space-between" {...props}>
      {data?.rating && (
        <Box display="flex" alignItems="center">
          {!hideNumber && (
            <Typography
              variant={variant}
              component="span"
              style={{ paddingRight: "5px", paddingTop: "2px" }}
              color={textColor}
            >
              <b>{data.rating}</b>
            </Typography>
          )}
          <Rating
            value={data.rating}
            size="small"
            emptyIcon={
              <StarIcon
                style={{ color: "rgba(0, 0, 0, 0.26)" }}
                fontSize="inherit"
              />
            }
            readOnly
            sx={{
              color: `${theme.palette.primary.light} !important`,
              fontSize: "1rem",
            }}
          />
        </Box>
      )}
      {!hideOpenNow && data.opening_hours?.isOpen() && (
        <Typography
          variant={variant}
          component="span"
          align="right"
          style={{
            color: theme.palette.success.main,
            fontWeight: 500,
          }}
        >
          {t__(theme.lang, common, "openNow")}
        </Typography>
      )}
    </Box>
  );
};

Ratings.defaultProps = {
  textColor: "textSecondary",
  variant: "h5",
};

Ratings.propTypes = {
  data: PropTypes.object,
  hideOpenNow: PropTypes.bool,
  hideNumber: PropTypes.bool,
  props: PropTypes.object,
  textColor: PropTypes.string,
  variant: PropTypes.string,
};

export default Ratings;
