import { Box, useTheme } from "@mui/material/";
import PropTypes from "prop-types";

const CityCircle = ({ border, cityImg, width, ...props }) => {
  const theme = useTheme();
  return (
    <Box
      height={width}
      width={width}
      border={border}
      borderColor={theme.palette.common.white}
      borderRadius={width}
      bgcolor={theme.palette.background.light}
      boxShadow="0 1px 3px 0 rgba(0, 0, 0, 0.3)"
      margin="0 auto"
      style={{
        background: `url(${cityImg}) center center no-repeat`,
        backgroundSize: "cover",
      }}
      {...props}
    />
  );
};

CityCircle.defaultProps = {
  border: 2,
  width: 68,
};

CityCircle.propTypes = {
  border: PropTypes.number,
  cityImg: PropTypes.string.isRequired,
  props: PropTypes.object,
  width: PropTypes.number,
};

export default CityCircle;
