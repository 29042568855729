import React, { useRef } from "react";
import { Box, useTheme, Typography, useMediaQuery } from "@mui/material/";
import t__ from "../../util/lang/util";
import intro from "../../util/lang/intro";
import cardsImg from "./images/test.jpg";
import bgImage from "./images/left-image@2x.jpg";
import isDefaultBrand from "../../util/isDefaultBrand";
import dgnLogo from "../Icons/DGN_AcceptanceMark_FC_Hrz_RGB.png";
import dinersLogo from "../Icons/asset-decal-diner@2x.png";
import Slider from "react-slick";
import "../../util/theme/slick.css";
import "../../util/theme/slick-theme.css";

const TravelWithConfidence = () => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const fullScreen = useMediaQuery(theme.breakpoints.up("md"));
  const slider = useRef(null);

  return (
    <Box
      display={isLargeScreen ? "flex" : "block"}
      width="100%"
      justifyContent="center"
      alignItems="center"
      bgcolor={theme.palette.background.light}
    >
      <Box
        width={{ xs: "100%", lg: "50%" }}
        height={fullScreen ? 550 : 400}
        position="relative"
      >
        <Slider
          arrows={false}
          autoplay={true}
          dots={false}
          infinite={true}
          speed={4800}
          slidesToShow={1}
          slidesToScroll={1}
          ref={slider}
          fade={true}
          pauseOnHover={false}
        >
          {" "}
          <Box
            overflow="hidden"
            height={fullScreen ? 550 : 400}
            width={"100%"}
            position="relative"
          >
            <Box
              height={fullScreen ? 550 : 400}
              width={"100% !important"}
              style={{
                backgroundImage: `url(${bgImage})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            ></Box>
          </Box>
          <Box
            overflow="hidden"
            height={fullScreen ? 550 : 400}
            width={"100%"}
            position="relative"
            sx={{
              display: "flex !important",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <img src={cardsImg} width="100%" alt="Array of DGN Network cards" />
          </Box>
        </Slider>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        py={{ xs: 10 }}
        px={{ xs: 5, md: "100px" }}
        textAlign={{ xs: "center", lg: "left" }}
        bgcolor="#F2F2F2"
        width={{ xs: "100%", lg: "50%" }}
      >
        <div
          data-aos="fade-left"
          data-aos-delay={400}
          data-aos-duration="500"
          data-aos-once="true"
        >
          <Box mb={2}>
            <Typography variant="subtitle2" component="h2">
              <b>{t__(theme.lang, intro, "confidenceHdr")}</b>
            </Typography>
          </Box>
        </div>
        <Box
          data-aos="fade-left"
          data-aos-delay={400}
          data-aos-duration="500"
          data-aos-once="true"
        >
          {t__(theme.lang, intro, "confidenceCopy")}
        </Box>
        <div
          data-aos="fade-left"
          data-aos-delay={500}
          data-aos-duration="500"
          data-aos-once="true"
        >
          <Box
            display="flex"
            justifyContent={isLargeScreen ? "left" : "center"}
            marginTop={5}
          >
            {isDefaultBrand(theme) || theme.brand === "cabal" ? (
              <>
                <Box mr={5}>
                  <img src={dinersLogo} alt="" width={87} height={64} />
                </Box>
                <Box>
                  <img src={dgnLogo} alt="" width={103} height={64} />
                </Box>
              </>
            ) : (
              <>
                <Box mr={5} display="flex" justifyContent="center">
                  <img src={theme.palette.brand.icon} alt="" height={62} />
                </Box>
                <Box>
                  <img src={dgnLogo} alt="" width={103} height={64} />
                </Box>
              </>
            )}
          </Box>
        </div>
      </Box>
    </Box>
  );
};

export default TravelWithConfidence;
