import { useCallback, useState } from "react";

/**
 * Implements Callback Refs
 * https://reactjs.org/docs/refs-and-the-dom.html#callback-refs
 *
 * This approach allows us to know when a ref is changed via simple re-rendering.
 */
export default function useCallbackRef(initialValue) {
  const [ref, setRef] = useState({ current: initialValue });
  const callback = useCallback((node) => {
    // Ref is changed, thus we trigger re-render.
    setRef({ current: node });
  }, []);
  return [ref, callback];
}
