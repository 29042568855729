import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material/";
import Container from "../Layout/Container";
import t__ from "../../util/lang/util";
import intro from "../../util/lang/intro";
import caribbean from "../../util/lang/caribbean";
import "../../util/theme/slick.css";
import "../../util/theme/slick-theme.css";
import Slider from "react-slick";
import isTouchDevice from "../../util/isTouchDevice";

import CATEGORIES from "../../util/categories";

const PREFIX = "FeaturedMerchants";

const classes = {
  slides: `${PREFIX}-slides`,

  card: `${PREFIX}-card`,
  media: `${PREFIX}-media`,
  firstBox: `${PREFIX}-firstBox`,
  secondBox: `${PREFIX}-secondBox`,
  last: `last`,
};
//I moved most of the style overrides for the slick slider to the slick.css file. Easier to target
const StyledContainer = styled(Container)(({ theme }) => ({
  [`& .${classes.secondBox}`]: {
    borderTopRightRadius: 12,
    borderBottomRightRadius: 12,
    [theme.breakpoints.down("sm")]: {
      borderTopRightRadius: 0,
    },
  },

  [`.${classes.last}`]: {
    "& .slick-dots li:last-child button": {
      [theme.breakpoints.down("sm")]: { opacity: 1 },
    },
    "& .slick-dots li:last-child button:before": {
      opacity: 1,
      [theme.breakpoints.down("sm")]: {
        backgroundColor: "#63686b",
      },
    },
  },
}));

const GenericMerchants = ({ merchants }) => {
  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.up("md"));
  const mdScreen = useMediaQuery(theme.breakpoints.up("sm"));

  const slidesToShow = () => {
    if (fullScreen) {
      return 4;
    }
    if (isTouchDevice() && mdScreen) {
      return 3.5;
    }
    if (mdScreen) {
      return 3;
    }
    if (isTouchDevice()) {
      return 2.25;
    }
    return 2;
  };

  const [lastClass, setLastClass] = useState(false);
  return (
    <Box
      mt={{ xs: 10, md: 20 }}
      mb={{ xs: 12, md: 20 }}
      mx="auto"
      className={lastClass ? classes.last : null}
    >
      <Box mb={{ xs: 8, md: 15 }}>
        <Typography align="center" component="h2" variant="subtitle2">
          <b>{t__(theme.lang, caribbean, `featuredMerchants.header`)}</b>
        </Typography>
      </Box>

      <Box
        data-aos="fade-right"
        data-aos-delay={500}
        data-aos-duration="500"
        data-aos-once="true"
      >
        <Slider
          arrows={true}
          dots={true}
          infinite={false}
          speed={500}
          slidesToShow={slidesToShow()}
          slidesToScroll={fullScreen ? 4 : mdScreen ? 3 : 2}
          className={`${classes.slides} caribbean ${
            isTouchDevice() ? "" : "nottouch"
          }`}
          beforeChange={(current, next) => {
            console.log(next + 2.25 === merchants.length);
            next + 2.25 === merchants.length
              ? setLastClass(true)
              : setLastClass(false);
          }}
        >
          {merchants.map((m) => {
            const cat =
              m.category === "Dining"
                ? "restaurants"
                : m.category.toLowerCase();

            return (
              <Box key={m.title}>
                <Box
                  height={64}
                  width={64}
                  borderRadius={64}
                  bgcolor="primary.dark"
                  mx="auto"
                  color="common.white"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  className="icon-box"
                >
                  {CATEGORIES.find((c) => c.mcc === cat).icon}
                </Box>
                <Typography variant="body2" align="center" mt={2} mb={3}>
                  {t__(theme.lang, intro, cat)}
                </Typography>
                <Typography
                  variant="body2"
                  align="center"
                  mb={1}
                  component="h3"
                >
                  <b>{m.title}</b>
                </Typography>
                <Typography variant="body2" align="center">
                  {m.city}
                </Typography>
              </Box>
            );
          })}
        </Slider>
      </Box>
    </Box>
  );
};

const FeaturedMerchants = () => {
  const theme = useTheme();

  return (
    <StyledContainer style={{ position: "relative" }}>
      <GenericMerchants
        merchants={caribbean[theme.lang].featuredMerchants.merchants}
      />
    </StyledContainer>
  );
};

export default FeaturedMerchants;
