// https://material-ui.com/customization/theming/
// https://material-ui.com/customization/default-theme/
import { createTheme } from "@mui/material/styles";
import breakpoints from "../default/breakpoints";
import palette from "./palette";
import spacing from "../default/spacing";
import typography from "../default/typography";
import footer from "./footer";
import overrides from "../default/overrides";

// Create a theme instance.
const cabal = createTheme({
  ...breakpoints,
  ...palette,
  ...spacing,
  ...typography,
  ...footer,
  components: overrides,
  headerMarks: ["Cabal"],
});
//set language to spanish
cabal.lang = "es";
cabal.brand = "cabal";

//reset all the link colors for theme
/*overrides.overrides.MuiCssBaseline["@global"]["a, a:visited, a:active"].color =
  "#00538B";*/

export default cabal;
