export default {
  MuiSelect: {
    selectMenu: {
      minHeight: "2.188em",
      lineHeight: "2.188em",
    },
    select: {
      "&:focus": {
        backgroundColor: "inherit",
      },
    },
  },
};
