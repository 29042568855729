import React, { useContext } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Link,
  Typography,
  useTheme,
} from "@mui/material/";

import countryView from "../../../util/lang/countryView";
import americasPhoto from "./images/Americas_willianJusten_unsplash_500px.jpg";
import asiaPhoto from "./images/AsiaPacific_sorasak_unsplash_500px.jpg";
import europePhoto from "./images/Europe_damianoBaschiera_unsplash_500px.jpg";
import middleEastPhoto from "./images/MiddleEast_andreeaCh_pexels_500px.jpg";
import { MapContext } from "../../../contexts";
import { useHistory } from "react-router-dom";
import isDefaultBrand from "../../../util/isDefaultBrand";
import trackLocationChange from "../../../util/trackLocationChange";

const PREFIX = "RegionTiles";

const classes = {
  card: `${PREFIX}-card`,
  media: `${PREFIX}-media`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.card}`]: {
    borderRadius: theme.spacing(3),
    "& a:focus": {
      outline: "none",
    },
  },

  [`& .${classes.media}`]: {
    height: theme.spacing(40),
    backgroundColor: theme.palette.secondary.main,
  },
}));

const RegionTiles = () => {
  const theme = useTheme();

  const history = useHistory();
  const photos = [asiaPhoto, europePhoto, middleEastPhoto, americasPhoto];
  const regionModules = countryView[theme.lang].regionModules;
  const modules = Object.keys(regionModules).map((c, ind) => {
    return {
      headline: regionModules[c].headline,
      image: photos[ind],
      description: regionModules[c].description,
      cta: regionModules[c].cta,
      links: regionModules[c].links,
    };
  });
  const {
    cities,
    locationShared,
    setLocationShared,
    setMapLoading,
    setMerchants,
    setFilter,
    setCityView,
  } = useContext(MapContext);

  const handleClick = (city) => {
    window.scrollTo(0, 0);
    setMapLoading(true);
    setMerchants(null);
    setFilter(null);
    setCityView(city);
    if (locationShared.length > 0) locationShared[0].setMap(null);
    setLocationShared([]);
    isDefaultBrand(theme)
      ? history.push(`/guides/${city.toLowerCase()}`)
      : history.push(`/guides/${city.toLowerCase()}?brand=${theme.brand}`);
    trackLocationChange();
  };

  if (!cities) {
    return null;
  }

  return modules.map((c, ind) => (
    <StyledGrid
      item
      xs={12}
      sm={6}
      lg={3}
      style={{ display: "flex" }}
      key={`region${ind}`}
    >
      <Card className={classes.card} elevation={0}>
        <CardMedia className={classes.media} image={c.image} />
        <CardContent style={{ padding: theme.spacing(5) }}>
          <Typography component="h4">
            <b>{c.headline}</b>
          </Typography>
          <Box mb={4} mt={3}>
            <Typography>{c.description}</Typography>
          </Box>
          <Typography>
            {c.cta}
            {c.links?.map((l, ind) => {
              return c.links.length === 1 ? (
                <b key={l.city}>
                  <Link
                    href={`/guides/${
                      cities.find((c) => c.city === l.city).route
                    }${!isDefaultBrand(theme) ? `?brand=${theme.brand}` : ``}`}
                    color="secondary"
                    onClick={(e) => {
                      e.preventDefault();
                      handleClick(l.city);
                    }}
                  >
                    {l.copy}
                  </Link>
                </b>
              ) : (
                <b key={l.city}>
                  <Link
                    href={`/guides/${
                      cities.find((c) => c.city === l.city).route
                    }${!isDefaultBrand(theme) ? `?brand=${theme.brand}` : ``}`}
                    color="secondary"
                    onClick={(e) => {
                      e.preventDefault();
                      handleClick(l.city);
                    }}
                  >
                    {l.copy}
                  </Link>
                  {ind < c.links.length - 1 ? ", " : ""}
                </b>
              );
            })}
          </Typography>
        </CardContent>
      </Card>
    </StyledGrid>
  ));
};

export default RegionTiles;
