import React, { useContext, useEffect } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  useTheme,
} from "@mui/material/";
import { getMerchantContent } from "../../Map/mapmethods";
import { MapContext } from "../../../contexts";
import CATEGORIES from "../../../util/categories";
import Ratings from "../Ratings";
import Container from "../../Layout/Container";
import CloseButton from "../../CloseButton";
import isDefaultBrand from "../../../util/isDefaultBrand";
import common from "../../../util/lang/common";
import t__ from "../../../util/lang/util";

const PREFIX = "MerchantTooltip";

const classes = {
  merchantTip: `${PREFIX}-merchantTip`,
  imagecover: `${PREFIX}-imagecover`,
  media: `${PREFIX}-media`,
  content: `${PREFIX}-content`,
  textOverflow: `${PREFIX}-textOverflow`,
  placeholder: `${PREFIX}-placeholder`,
};

const StyledContainer = styled(Container)(({ theme }) => ({
  /*[`& .${classes.merchantTip}`]: {
    borderRadius: theme.spacing(3),
    border: `1px solid ${theme.palette.borders.light}`,
    position: "absolute",
    bottom: "30px",
    left: "30px",
    backgroundColor: theme.palette.common.white,
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.25)",
    [theme.breakpoints.down("sm")]: {
      left: "calc(50% - 169px)",
    },
    "@media (max-width: 320px)": {
      left: "calc(50% - 149px)",
    },
  },*/

  /*[`& .${classes.imagecover}`]: {
    position: "absolute",
    left: "-10000%",
    right: "-10000%",
    top: "-10000%",
    bottom: "-10000%",
    margin: "auto auto",
    minWidth: "1000%",
    minHeight: "1000%",
    "-webkit-transform": "scale(0.1)",
    transform: "scale(0.1)",
  },*/

  [`& .${classes.media}`]: {
    minHeight: theme.spacing(30),
    width: theme.spacing(25),
    backgroundColor: theme.palette.background.light,
    overflow: "hidden",
    position: "relative",
    "@media (max-width: 320px)": {
      width: theme.spacing(20),
    },
  },

  /*[`& .${classes.content}`]: {
    width: theme.spacing(59),
    color: theme.palette.text.secondary,
    paddingTop: theme.spacing(2),
    paddingBottom: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: theme.spacing(30),
    "@media (max-width: 320px)": {
      width: theme.spacing(54),
    },
  },*/

  /*[`& .${classes.textOverflow}`]: {
    width: "200px",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    marginBottom: theme.spacing(2),
    "@media (max-width: 320px)": {
      width: "150px",
    },
  },*/

  /*[`& .${classes.placeholder}`]: {
    color: theme.palette.common.white,
    backdropFilter: "blur(4px)",
    background: "rgba(255, 255, 255, 0.5)",
    height: theme.spacing(30),
    width: 100,
    position: "absolute",
    left: 0,
    "& svg": {
      transform: "scale(2)",
      position: "absolute",
      top: theme.spacing(13),
    },
  },*/
}));

const MerchantTooltip = ({
  activePin,
  merchantData,
  map,
  setMerchantTooltip,
}) => {
  const theme = useTheme();

  const { cityView, googleData, setGoogleData, setDrawer } =
    useContext(MapContext);

  useEffect(() => {
    async function getData(id) {
      if (googleData && googleData[id]) {
        return googleData[id];
      } else {
        const value = await getMerchantContent(id, map, theme.lang);

        let address = value.formatted_address;
        if (
          ["New York", "Los Angeles", "Miami", "Honolulu"].includes(cityView)
        ) {
          const streetnum = value.address_components.filter((a) =>
            a.types.includes("street_number")
          )[0];
          const street = value.address_components.filter((a) =>
            a.types.includes("route")
          )[0];
          address = `${streetnum ? streetnum.long_name : ""} ${
            street ? street.long_name : ""
          }`;

          if (address === " ") {
            address = value.formatted_address;
          }
        }

        delete value.address_components;

        const objData = {
          [id]: {
            name: merchantData.name,
            address: address,
            mcc: merchantData.mcc,
            ...value,
          },
        };
        setGoogleData(
          Object.keys(googleData).length
            ? { ...googleData, ...objData }
            : objData
        );
        return objData;
      }
    }
    getData(merchantData.google_place_id);
  }, [cityView, googleData, map, merchantData, setGoogleData, theme.lang]);

  if (googleData[merchantData.google_place_id]) {
    const data = googleData[merchantData.google_place_id];
    return (
      <StyledContainer position="relative">
        <Card
          //className={classes.merchantTip}
          sx={{
            borderRadius: theme.spacing(3),
            border: `1px solid ${theme.palette.borders.light}`,
            position: "absolute",
            bottom: "30px",
            left: "30px",
            backgroundColor: theme.palette.common.white,
            boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.25)",
            [theme.breakpoints.down("sm")]: {
              left: "calc(50% - 169px)",
            },
            "@media (max-width: 320px)": {
              left: "calc(50% - 149px)",
            },
          }}
        >
          <CloseButton
            topRight
            onClick={() => {
              setMerchantTooltip(null);
              activePin.set("active", false);
              //activePin.setIcon(`/${activePin.merchantData.mcc}_default.svg`);
              activePin.setIcon(
                `${!isDefaultBrand(theme) ? `/brand/${theme.brand}/` : "/"}${
                  activePin.merchantData.mcc
                }_default.svg`
              );
            }}
            style={{ padding: "16px", top: 0, right: 0 }}
          />
          <CardActionArea
            style={{ display: "flex", alignItems: "start", height: "100%" }}
            onClick={() => {
              setDrawer(merchantData);
              setMerchantTooltip(null);
            }}
          >
            {data.photos && data.photos[0].getUrl() ? (
              <CardMedia
                /*className={classes.media}*/
                sx={{
                  minHeight: theme.spacing(30),
                  width: theme.spacing(25),
                  backgroundColor: theme.palette.background.light,
                  overflow: "hidden",
                  position: "relative",
                  "@media (max-width: 320px)": {
                    width: theme.spacing(20),
                  },
                }}
              >
                <img
                  src={data.photos[0].getUrl()}
                  //className={classes.imagecover}
                  style={{
                    position: "absolute",
                    left: "-10000%",
                    right: "-10000%",
                    top: "-10000%",
                    bottom: "-10000%",
                    margin: "auto auto",
                    minWidth: "1000%",
                    minHeight: "1000%",
                    "-webkit-transform": "scale(0.1)",
                    transform: "scale(0.1)",
                  }}
                  alt=""
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = `/PlaceholderImages/${CATEGORIES.find(
                      (c) => c.mcc === data.mcc
                    )?.title.toLowerCase()}/photo${Math.floor(
                      Math.random() * 3 + 1
                    )}.jpg`;
                  }}
                />
              </CardMedia>
            ) : (
              <>
                <CardMedia
                  //className={classes.media}
                  sx={{
                    minHeight: theme.spacing(30),
                    width: theme.spacing(25),
                    backgroundColor: theme.palette.background.light,
                    overflow: "hidden",
                    position: "relative",
                    "@media (max-width: 320px)": {
                      width: theme.spacing(20),
                    },
                  }}
                  image={`/PlaceholderImages/${CATEGORIES.find(
                    (c) => c.mcc === data.mcc
                  )?.title.toLowerCase()}/photo${Math.floor(
                    Math.random() * 3 + 1
                  )}.jpg`}
                />
                <Box
                  className={classes.placeholder}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    color: theme.palette.common.white,
                    backdropFilter: "blur(4px)",
                    background: "rgba(255, 255, 255, 0.5)",
                    height: theme.spacing(30),
                    width: 100,
                    position: "absolute",
                    left: 0,
                    "& svg": {
                      transform: "scale(2)",
                      position: "absolute",
                      top: theme.spacing(13),
                    },
                  }}
                >
                  {CATEGORIES.find((c) => c.mcc === data.mcc)?.icon}
                </Box>
              </>
            )}
            <CardContent
              className={classes.content}
              sx={{
                width: theme.spacing(59),
                color: theme.palette.text.secondary,
                paddingTop: theme.spacing(2),
                paddingBottom: 0,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                minHeight: theme.spacing(30),
                "@media (max-width: 320px)": {
                  width: theme.spacing(54),
                },
              }}
            >
              <Box>
                <Typography
                  variant="body2"
                  style={{
                    color: theme.palette.secondary.main,
                    marginBottom: theme.spacing(1),
                    marginRight: theme.spacing(5),
                  }}
                >
                  <b>{data.name}</b>
                </Typography>
                <Typography
                  variant="h5"
                  component="p"
                  sx={{
                    width: "200px",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    marginBottom: theme.spacing(2),
                    "@media (max-width: 320px)": {
                      width: "150px",
                    },
                  }}
                >
                  {data.address}
                </Typography>
                <Box
                  display="flex"
                  color="text.secondary"
                  alignItems="center"
                  marginTop={1}
                >
                  <span>
                    {CATEGORIES.find((c) => c.mcc === data.mcc)?.icon}
                  </span>
                  {data.price_level && (
                    <Typography
                      component="span"
                      color="textSecondary"
                      variant="h5"
                      style={{
                        paddingLeft: theme.spacing(3),
                      }}
                    >
                      {[...Array(data.price_level)].map(() => "$")}
                      <span style={{ position: "relative", left: "6px" }}>
                        &bull;
                      </span>
                    </Typography>
                  )}

                  <Typography
                    component="span"
                    color="textSecondary"
                    variant="h5"
                    style={{
                      paddingLeft: theme.spacing(3),
                    }}
                  >
                    {t__(
                      theme.lang,
                      common,
                      `categories.${CATEGORIES.find(
                        (c) => c.mcc === data.mcc
                      )?.title.toLowerCase()}`
                    )}
                  </Typography>
                </Box>
              </Box>
              <Ratings data={data} paddingBottom={theme.spacing(1)} />
            </CardContent>
          </CardActionArea>
        </Card>
      </StyledContainer>
    );
  } else {
    return null;
  }
};

MerchantTooltip.propTypes = {
  activePin: PropTypes.object,
  map: PropTypes.object.isRequired,
  merchantData: PropTypes.object.isRequired,
  setMerchantTooltip: PropTypes.func,
};

export default MerchantTooltip;
