import React, { useContext, useRef, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useMediaQuery, useTheme } from "@mui/material/";
import "../../../util/theme/slick.css";
import "../../../util/theme/slick-theme.css";
import Slider from "react-slick";
import CityTile from "../CityTile";
import { MapContext } from "../../../contexts";
import { useHistory } from "react-router-dom";
import trackLocationChange from "../../../util/trackLocationChange";

const PREFIX = "CityTiles";

const classes = {
  destinations: `${PREFIX}-destinations`,
};

const StyledSlider = styled(Slider)(({ theme }) => ({
  [`&.${classes.destinations}`]: {
    marginTop: theme.spacing(7),
    marginLeft: `-${theme.spacing(2)}`,
    [theme.breakpoints.down("sm")]: {
      marginRight: `-${theme.spacing(7)}`,
    },
    "& a:hover": {
      color: theme.palette.common.white,
    },
    "& .slick-disabled": {
      display: "none !important",
    },
    "& .slick-next, & .slick-prev": {
      height: theme.spacing(10),
      width: theme.spacing(10),
      borderRadius: theme.spacing(10),
      background: theme.palette.common.white,
      boxShadow: "0 2px 6px 0 rgba(0, 0, 0, 0.3)",
      transition: "all 0.4s",
      zIndex: 1,

      "&:before": {
        opacity: 1,
        content: `" "`,
        height: 15,
        width: 15,
        border: `3px solid ${theme.palette.secondary.main}`,
        display: "block",
        transform: "rotate(45deg)",
        borderLeft: 0,
        borderBottom: 0,
        marginLeft: 9,
      },
      "&:hover, &:focus": {
        "&:before": {
          borderColor: theme.palette.primary.main,
        },
      },
      [theme.breakpoints.down("sm")]: {
        display: "none !important",
      },
    },
    "& .slick-prev:before": {
      transform: "rotate(225deg) !important",
      marginLeft: "14px !important",
    },
    "& .slick-next": {
      right: "-14px",
    },
    "& .slick-prev": {
      left: "-14px",
    },
    "& .slick-track": {
      marginLeft: 0,
      marginRight: 0,
    },
  },
}));

const CityTiles = ({ citiesList, showCaribbean }) => {
  const history = useHistory();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.up("md"));
  const mdScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const sliderRef = useRef();
  const {
    cities,
    setCityView,
    setMapLoading,
    setMerchants,
    setFilter,
    locationShared,
    setLocationShared,
  } = useContext(MapContext);
  //if there are less slides than slides to show for some reason the carousel aligns them to the right side with a css transform. so here is a hack to fix that. maybe in the future this issue will be fixed.
  useEffect(() => {
    if (sliderRef.current) {
      setTimeout(() => {
        if (sliderRef.current?.innerSlider) {
          sliderRef.current.innerSlider.track.node.style.transform =
            "translate3d(0px, 0px, 0px)";
        }
      }, 100);
    }
  });

  if (!cities) return null;

  return (
    <>
      <StyledSlider
        dots={false}
        infinite={false}
        speed={500}
        slidesToShow={fullScreen ? 3 : mdScreen ? 2 : 1.5}
        slidesToScroll={fullScreen ? 3 : mdScreen ? 2 : 1}
        className={classes.destinations}
        ref={sliderRef}
      >
        {citiesList?.map((c) => {
          const route = cities.find((d) => d.city === c.city).route;
          return (
            <div key={c.city}>
              <CityTile
                city={c}
                route={route}
                height={{
                  xs: theme.spacing(29),
                  sm: theme.spacing(32),
                  md: theme.spacing(40),
                }}
                onClick={(e) => {
                  e.preventDefault();
                  window.scrollTo(0, 0);
                  setMapLoading(true);
                  setMerchants(null);
                  setFilter(null);
                  setCityView(c.city);
                  if (locationShared.length > 0) locationShared[0].setMap(null);
                  setLocationShared([]);
                  history.push(`/guides/${route}`);
                  trackLocationChange();
                }}
                titleVariant={mdScreen ? "h2" : "h3"}
              />
            </div>
          );
        })}
        {showCaribbean && (
          <CityTile
            city={{ city: "Caribbean" }}
            route="caribbean"
            height={{
              xs: theme.spacing(29),
              sm: theme.spacing(32),
              md: theme.spacing(40),
            }}
            onClick={(e) => {
              e.preventDefault();
              window.scrollTo(0, 0);
              setMapLoading(true);
              setMerchants(null);
              setFilter(null);
              setCityView(null);
              if (locationShared.length > 0) locationShared[0].setMap(null);
              setLocationShared([]);
              history.push(`/guides/caribbean`);
              trackLocationChange();
            }}
            titleVariant={mdScreen ? "h2" : "h3"}
          />
        )}
      </StyledSlider>
    </>
  );
};

export default CityTiles;
