import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { Box, Link, Typography, useTheme } from "@mui/material/";
import starIcon from "../../Icons/popular-star.svg";
import newIcon from "../../Icons/new-exclamation.svg";
import isDefaultBrand from "../../../util/isDefaultBrand";

const PREFIX = "CityTile";

const classes = {
  scrim: `${PREFIX}-scrim`,
};

const StyledLink = styled(Link)(({ theme }) => ({
  [`&.${classes.scrim}`]: {
    color: theme.palette.common.white,
    "& .scrim": {
      transition: "all .4s",
      borderRadius: "12px",
    },
    "&:hover, &:focus": {
      outline: "none",
      "& .scrim": {
        backgroundColor: "rgba(0, 0, 0, 0.4)",
      },
    },
  },
}));

//dynamic import all the city images
const context = require.context(
  "../../CityImages/500",
  false,
  /\.(png|jpe?g|svg)$/
);
const cityImages = {};
context.keys().forEach((key) => {
  const prefix = key
    .split("./")
    .pop() // remove the first 2 characters
    .substring(0, key.length - 6); // remove the file extension
  cityImages[prefix] = context(key);
});

const CityTile = ({ city, height, onClick, route, titleVariant }) => {
  const theme = useTheme();
  const imageKey = city.city.toLowerCase().replace(" ", "") + "_500";

  return (
    <StyledLink
      href={
        isDefaultBrand(theme)
          ? `/guides/${route}`
          : `/guides/${route}?brand=${theme.brand}`
      }
      underline="none"
      className={classes.scrim}
      onClick={onClick}
    >
      <Box
        borderRadius={3}
        bgcolor="secondary.main"
        style={{
          backgroundImage: `url(${cityImages[imageKey]})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
        }}
        marginX={2}
        color="common.white"
        height={height}
        display="flex"
        alignItems="center"
        justifyContent="center"
        position="relative"
      >
        {" "}
        {city.callout && (
          <Box
            bgcolor="common.white"
            zIndex={1}
            position="absolute"
            left={theme.spacing(3)}
            top={theme.spacing(3)}
            display="flex"
            borderRadius={3}
            paddingBottom={1}
            paddingTop={1}
            paddingX={3}
          >
            <img
              src={city.callout === "New!" ? newIcon : starIcon}
              alt={""}
              width={city.callout === "New!" ? "16px" : "14px"}
              height={city.callout === "New!" ? "16px" : "14px"}
            />
            <Typography
              variant="h5"
              component="span"
              color="textPrimary"
              style={{
                paddingLeft: theme.spacing(1),
              }}
            >
              <b>{city.callout}</b>
            </Typography>
          </Box>
        )}
        <Typography
          variant={titleVariant}
          style={{ position: "relative", zIndex: 1 }}
          align="center"
        >
          <b>{city.translated_city ?? city.city}</b>
        </Typography>
        <Box
          height={height}
          width="100%"
          bgcolor="rgba(0, 0, 0, 0.3)"
          position="absolute"
          top={0}
          borderRadius={theme.spacing(3)}
          className="scrim"
        ></Box>
      </Box>
    </StyledLink>
  );
};

CityTile.propTypes = {
  city: PropTypes.object,
  onClick: PropTypes.func,
  route: PropTypes.string,
  titleVariant: PropTypes.string,
};

export default CityTile;
