import { palette } from "../palette";

export default {
  MuiButton: {
    root: {
      textTransform: "none",
      "&$focusVisible": {
        background: palette.background.light,
      },
    },
  },
  MuiTouchRipple: {
    root: {
      display: "none",
    },
  },
};
