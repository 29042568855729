import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { IconButton } from "@mui/material/";
import closeXSvg from "../Icons/closeX.svg";
import cx from "classnames";

const PREFIX = "CloseButton";

const classes = {
  closeXButton: `${PREFIX}-closeXButton`,
  topRight: `${PREFIX}-topRight`,
};

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  [`&.${classes.closeXButton}`]: {
    padding: 14,
    fontSize: 0,
    overflow: "hidden",
    width: 14,
    height: 14,
    zIndex: 2,
    borderRadius: 14,
    display: "flex",
    "& img": {
      height: 14,
      width: 14,
      position: "absolute",
    },
    "&:focus": {
      background: theme.palette.background.light,
    },
  },

  [`& .${classes.topRight}`]: {
    position: "absolute",
    top: 5,
    right: 5,
  },
}));

const CloseButton = ({ alt, onClick, topRight, ...props }) => {
  return (
    <StyledIconButton
      className={cx(classes.closeXButton, props.className)}
      sx={topRight && { position: "absolute", top: 5, right: 5 }}
      onClick={onClick}
      {...props}
      size="large"
    >
      <img src={closeXSvg} alt={alt} />
    </StyledIconButton>
  );
};

CloseButton.defaultProps = {
  alt: "Close",
};

CloseButton.propTypes = {
  alt: PropTypes.string,
  onClick: PropTypes.func,
  topRight: PropTypes.bool,
  props: PropTypes.object,
};

export default CloseButton;
