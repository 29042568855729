import React from "react";
import themes from "./util";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import Content from "./components/Content";
import { QueryClient, QueryClientProvider } from "react-query";
import CssBaseline from "@mui/material/CssBaseline";
import { MapContextProvider } from "./contexts";
import "./util/theme/fonts/fonts.css";
import AOS from "aos";
import "aos/dist/aos.css";

//if issuer theme deal with it upfront, includes setting the language
const queryClient = new QueryClient();
const params = new URLSearchParams(window.location.search);
let theme = themes[params.get("brand")] ?? themes.default;
if (
  window.location.pathname === "/cabal/" ||
  window.location.pathname === "/cabal"
) {
  theme = themes["cabal"];
}

function App() {
  //const [ip, setIp] = useState(false);

  /*async function getIp() {
    try {
      let response = await fetch(
        `https://pro.ip-api.com/json/?fields=61439&key=zgFcRaRb2JY7XQ0`
      );
      const resp = await response.json();
      setIp(resp);
    } catch (err) {
      console.error(err);
    }
  }*/

  /*window.getIp = getIp;

  useEffect(() => {
    if (
      document.cookie.includes("_evidon_consent_cookie") ||
      process.env.NODE_ENV === "development"
    ) {
      window.getIp();
    }
  }, []);*/

  AOS.init();

  return (
    <QueryClientProvider client={queryClient}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />

          <MapContextProvider>
            <Content ip={false} />
          </MapContextProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </QueryClientProvider>
  );
}

export default App;
