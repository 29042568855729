import { useState, useEffect, useRef } from "react";
// https://github.com/nvkelso/natural-earth-vector/tree/master/geojson

/**
 * Google Maps hook.
 *
 * @param google - Google Maps API instance, utilize useGoogleMapsApi() hook to get it.
 * @param mapOptions - Google Maps API options
 */
export function useGoogleMaps(google, mapOptions) {
  const ref = useRef(null);
  const [map, setMap] = useState();
  const [markers, setMarkers] = useState([]);

  function setMapCenter({ lat, lng }) {
    if (!map) {
      return;
    }

    map.panTo(new google.maps.LatLng(lat, lng));
  }

  function fitBounds(markers = []) {
    if (!map) {
      return;
    }

    const bounds = markers.reduce((bounds, marker) => {
      bounds.extend(marker.getPosition());
      return bounds;
    }, new google.maps.LatLngBounds());

    map.fitBounds(bounds, 0);
  }

  function centerBoundsAndZoom(markers = [], level) {
    if (!map || markers.length === 0 || !markers[0].getPosition()) {
      return;
    }

    let lat = 0;
    let lng = 0;
    //make this a loop instead
    markers.forEach((marker) => {
      lat = lat + marker.getPosition().lat();
      lng = lng + marker.getPosition().lng();
    });

    const location = new google.maps.LatLng(
      lat / markers.length,
      lng / markers.length
    );

    //just zoom in and pan to that closest center marker
    map.setZoom(level ?? 12);
    map.panTo(location);
  }

  useEffect(() => {
    if (!google || !ref) {
      return;
    }
    setMap(new google.maps.Map(ref.current, mapOptions));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [google, ref]);

  return {
    ref,
    map,
    markers,
    setMarkers,
    setMapCenter,
    fitBounds,
    centerBoundsAndZoom,
  };
}
