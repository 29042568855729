import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Paper, Typography, useTheme } from "@mui/material/";
import { MAPCOLORS } from "../Map/mapstyles";
import translation from "../../util/lang/acceptanceLevels";
import t__ from "../../util/lang/util";
import MapLegendModal from "./MapLegendModal";
import isDefaultBrand from "../../util/isDefaultBrand";
import adjustColor from "../../util/adjustColor";

const PREFIX = "MapLegend";

const classes = {
  mapLegend: `${PREFIX}-mapLegend`,
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  [`&.${classes.mapLegend}`]: {
    position: "absolute",
    bottom: 30,
    left: 30,
    padding: theme.spacing(3),
    paddingRight: 0,
    paddingTop: theme.spacing(2),
    borderRadius: 8,
    width: 200,
    [theme.breakpoints.down("sm")]: {
      left: 20,
    },
  },
}));

const MapLegend = ({ ...props }) => {
  const theme = useTheme();

  const mapColors = isDefaultBrand(theme)
    ? MAPCOLORS.default
    : {
        "NO COVERAGE": "#f2f2f2",
        J: adjustColor(theme.palette.brand.map, 0.2),
        G: adjustColor(theme.palette.brand.map, 0.45),
        H: theme.palette.brand.map,
      };

  return (
    <StyledPaper className={classes.mapLegend} elevation={3}>
      <Typography variant="caption" fontWeight={500}>
        {t__(theme.lang, translation, "legend.headline")}
      </Typography>
      <Box
        display="flex"
        width="100%"
        justifyContent="space-between"
        mt={1}
        alignItems="center"
      >
        <Box width={150}>
          <Box
            borderRadius="6px"
            border={1}
            height={12}
            display="flex"
            overflow="hidden"
          >
            {Object.keys(mapColors).map((m, ind) => {
              const len = Object.keys(mapColors).length;
              return (
                <Box
                  width={1 / len}
                  bgcolor={mapColors[m]}
                  borderRight={ind + 1 === len ? 0 : 1}
                  key={"m" + ind}
                ></Box>
              );
            })}
          </Box>
          <Box display="flex" width="100%" justifyContent="space-between">
            <Typography variant="h6" component="span">
              0%
            </Typography>{" "}
            <Typography variant="h6" component="span">
              100%
            </Typography>
          </Box>
        </Box>
        <MapLegendModal />
      </Box>
    </StyledPaper>
  );
};

export default MapLegend;
