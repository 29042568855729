const trackingKey = "uHLYlNVwIo6Bi0Puok1Z39NA2hjUUnwQLh6Tftn4"; //not for security, instead it is used for metrics, performance and debugging.

async function getCountryAcceptance() {
  return await fetch(
    "https://0wgjxu6jh2.execute-api.us-west-2.amazonaws.com/api/country-acceptance/v2/coverage?pagesize=100000&card_network=DN",
    {
      method: "GET",
      headers: {
        "x-api-key": trackingKey,
        Accept: "application/json",
      },
    }
  );
}

export async function getCountries() {
  return await fetch(
    "https://0wgjxu6jh2.execute-api.us-west-2.amazonaws.com/api/country-acceptance/v2/countries?pagesize=100000&card_network=DN",
    {
      method: "GET",
      headers: {
        "x-api-key": trackingKey,
        Accept: "application/json",
      },
    }
  );
}

//type: ["cities", "merchants", "categories"]
export async function getCityGuideData(type, params) {
  const url = new URL(
    "https://0wgjxu6jh2.execute-api.us-west-2.amazonaws.com/api/cityguides/v2/" +
      type
  );
  url.search = new URLSearchParams(params);
  return await fetch(url, {
    headers: {
      "x-api-key": trackingKey,
      Accept: "application/json",
    },
  });
}

export async function getSearchData(params) {
  const url = new URL(
    "https://0wgjxu6jh2.execute-api.us-west-2.amazonaws.com/api/cityguides/v2/merchants/autocomplete"
  );
  url.search = new URLSearchParams(params);

  return await fetch(url, {
    headers: {
      "x-api-key": trackingKey,
      Accept: "application/json",
    },
  });
}

export default getCountryAcceptance;
