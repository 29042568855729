import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Footer from "../Footer";
import { Box, Link, useTheme } from "@mui/material/";
import Container from "../Layout/Container";
import AcceptanceMarks from "../AcceptanceMarks";
import isDefaultBrand from "../../util/isDefaultBrand";
import { Helmet } from "react-helmet";
import caribbean from "../../util/lang/caribbean";
import t__ from "../../util/lang/util";
import ErrorBoundary from "../ErrorBoundary";
import Marquee from "./Marquee";
import FeaturedMerchants from "./FeaturedMerchants";
import AcceptedAt from "./AcceptedAt";
import Destinations from "./Destinations";

const PREFIX = "Intro";
const classes = {
  logos: `${PREFIX}-logos`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.logos}`]: {
    display: "inline-block",
    "& img": {
      transition: "all .2s",
    },
    "&:focus, &:hover": {
      "& img": {
        boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.3)",
      },
    },
  },
}));

const Caribbean = ({ acceptanceData, cities }) => {
  const theme = useTheme();

  return (
    <Root>
      <Helmet>
        <title>{t__(theme.lang, caribbean, "siteTitle")}</title>
        <meta
          name="description"
          content={t__(theme.lang, caribbean, "pageDescription")}
        />
        <meta name="keywords" content="Discover card traveling abroad" />
        <link
          rel="canonical"
          href="https://travel.discoverglobalnetwork.com/"
        />
      </Helmet>
      <Box style={{ overflowX: "hidden" }}>
        <ErrorBoundary>
          <Box
            bgcolor="common.white"
            height={62}
            display="flex"
            alignItems="center"
          >
            <Container>
              <Link
                href={isDefaultBrand(theme) ? "/" : `/?brand=${theme.brand}`}
                className={classes.logos}
              >
                <AcceptanceMarks
                  marks={
                    isDefaultBrand(theme) ? ["DCI", "DN"] : theme.headerMarks
                  }
                  variant="xs"
                />
              </Link>
            </Container>
          </Box>
          <Marquee />
          <Destinations acceptanceData={acceptanceData} cities={cities} />
          <FeaturedMerchants />
          <AcceptedAt />
          <Footer />
        </ErrorBoundary>
      </Box>
    </Root>
  );
};

Caribbean.propTypes = {
  cities: PropTypes.array,
  countryData: PropTypes.array,
};

export default Caribbean;
