/* eslint-disable import/no-anonymous-default-export */
// https://material-ui.com/customization/palette/
import defaultPalette from "../default/palette";
import troyLogo from "../../../../src/components/Icons/Troy_logo.png";

export const palette = {
  ...defaultPalette.palette,
  brand: {
    primaryBg: "#1fb8c4",
    text: "#000",
    map: "#1fb8c4",
    icon: troyLogo,
  },
};

export default {
  palette,
};
