import React from "react";
import PropTypes from "prop-types";
import { Box, Typography, useTheme } from "@mui/material/";
import common from "../../../util/lang/common";
import t__ from "../../../util/lang/util";
import { ReactComponent as MarkIcon } from "../../Icons/mark.svg";

const TravelGuidesLogo = ({ variant, borderBottom }) => {
  const theme = useTheme();

  return (
    <Box display="flex">
      <Box mr={2}>
        <MarkIcon />
      </Box>
      <Box>
        <Typography
          variant={variant}
          component="span"
          sx={{
            borderBottom: borderBottom
              ? borderBottom
              : `2px solid ${theme.palette.primary.main}`,
          }}
        >
          <b>{t__(theme.lang, common, "travelGuides")}</b>
        </Typography>
      </Box>
    </Box>
  );
};

TravelGuidesLogo.defaultProps = {
  variant: "h4",
};

TravelGuidesLogo.propTypes = {
  props: PropTypes.object,
  variant: PropTypes.string,
};

export default TravelGuidesLogo;
