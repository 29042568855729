/* eslint-disable import/no-anonymous-default-export */
// https://material-ui.com/customization/breakpoints/

export default {
  breakpoints: {
    values: {
      xs: 0,
      xsm: 320,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
      xxl: 1600,
    },
  },
};
