import React, { useContext, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material/";
import CATEGORIES from "../../../util/categories";
import Pagination from "@mui/material/Pagination";
import { MapContext } from "../../../contexts";
import { getMerchantContent } from "../../Map/mapmethods";
import Ratings from "../Ratings";
import common from "../../../util/lang/common";
import t__ from "../../../util/lang/util";
import isDefaultBrand from "../../../util/isDefaultBrand";

const PREFIX = "MerchantsTable";

const classes = {
  card: `${PREFIX}-card`,
  media: `${PREFIX}-media`,
  placeholder: `${PREFIX}-placeholder`,
  pagination: `${PREFIX}-pagination`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.card}`]: {
    borderRadius: theme.spacing(3),
    border: `1px solid ${theme.palette.borders.light}`,
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "row",
      minHeight: "130px",
    },
  },

  [`& .${classes.media}`]: {
    height: theme.spacing(30),
    backgroundColor: theme.palette.background.light,
    overflow: "hidden",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      height: "auto",
      minWidth: "35%",
    },
    "& svg": {
      transform: "scale(3)",
    },
    "& img": {
      position: "absolute",
      left: "-10000%",
      right: "-10000%",
      top: "-10000%",
      bottom: "-10000%",
      margin: "auto auto",
      minWidth: "1000%",
      minHeight: "1000%",
      "-webkitTransform": "scale(0.1)",
      transform: "scale(0.1)",
    },
  },

  [`& .${classes.placeholder}`]: {
    color: theme.palette.common.white,
    backdropFilter: "blur(4px)",
    background: "rgba(255, 255, 255, 0.5)",
    height: theme.spacing(30),
    width: "100%",
    position: "absolute",
    [theme.breakpoints.down("sm")]: {
      width: "35%",
      minHeight: 128,
      height: "100%",
    },
    "& svg": {
      transform: "scale(3)",
      position: "absolute",
      top: theme.spacing(13),
    },
  },
}));

const StyledPagination = styled(Pagination)(({ theme }) => ({
  [`&.${classes.pagination}`]: {
    marginBottom: theme.spacing(10),

    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(20),
    },
    "& ul": {
      justifyContent: "center",
    },
    "& .MuiPaginationItem-page": {
      color: theme.palette.secondary.main,
      fontWeight: "bold",
      fontSize: "1rem",
      height: 36,
      minWidth: 36,
      borderRadius: 18,
      "&.Mui-selected": {
        backgroundColor: isDefaultBrand(theme)
          ? theme.palette.primary.main
          : theme.palette.secondary.main,
        color: theme.palette.common.white,
        "&:hover, &:focus": {
          backgroundColor: isDefaultBrand(theme)
            ? theme.palette.secondary.main
            : theme.palette.common.black,
        },
      },
      "& svg": {
        fontSize: 40,
      },
    },
    "& .MuiPagination-ul": {
      flexWrap: "noWrap",
    },
    "& .MuiPagination-ul li:first-child": {
      paddingRight: theme.spacing(5),
      "& .MuiPaginationItem-icon": {
        fontSize: 40,
        color: theme.palette.secondary.main,
      },
      [theme.breakpoints.up("md")]: {
        paddingRight: theme.spacing(12),
      },
      "@media (max-width: 320px)": {
        paddingRight: theme.spacing(3),
      },
    },
    "& .MuiPagination-ul li:last-child": {
      paddingLeft: theme.spacing(5),
      "& .MuiPaginationItem-icon": {
        fontSize: 40,
        color: theme.palette.secondary.main,
      },
      [theme.breakpoints.up("md")]: {
        paddingLeft: theme.spacing(12),
      },
      "@media (max-width: 320px)": {
        paddingLeft: theme.spacing(3),
      },
    },
  },
}));

const MerchantsTable = ({ map, setDrawer, visibleMerchants }) => {
  const [currentMerchants, setCurrentMerchants] = useState(null);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();

  const smScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [currentPage, setCurrentPage] = useState(1);
  const { filter, cityView, googleData, setGoogleData } =
    useContext(MapContext);

  async function getPlacesData(a) {
    setLoading(true);
    const data = a.map(async (t) => {
      if (!t) {
        return;
      }
      if (googleData && googleData[t.google_place_id]) {
        return googleData[t.google_place_id];
      } else {
        const value = await getMerchantContent(
          t.google_place_id,
          map,
          theme.lang
        );
        return value;
      }
    });

    const values = await Promise.all(data);
    setLoading(false);
    let currentGoogleData = { ...googleData };

    values
      .filter((v) => v !== undefined)
      .forEach((v) => {
        const existing = currentMerchants.find(
          (a) => a.google_place_id === v.place_id
        );
        if (!existing) return;

        let address = v.formatted_address;
        if (
          ["New York", "Los Angeles", "Miami", "Honolulu"].includes(cityView)
        ) {
          if (v.address_components) {
            const streetnum = v.address_components.filter((a) =>
              a.types.includes("street_number")
            )[0];
            const street = v.address_components.filter((a) =>
              a.types.includes("route")
            )[0];
            address = `${streetnum ? streetnum.long_name : ""} ${
              street ? street.long_name : ""
            }`;
          }
        }

        if (address === " ") {
          address = v.formatted_address;
        }

        if (v.address_components) {
          delete v.address_components;
        }

        const objData = {
          [v.place_id]: {
            name: existing.name,
            address: address,
            mcc: existing.mcc,
            loaded: true,
            ...v,
          },
        };

        currentGoogleData = { ...currentGoogleData, ...objData };
      });
    setGoogleData(
      Object.keys(googleData).length
        ? { ...googleData, ...currentGoogleData }
        : currentGoogleData
    );
  }

  const updatePagination = (event, value) => {
    window.scrollTo(0, 0);
    setCurrentPage(value);
  };

  useEffect(() => {
    setCurrentMerchants(
      visibleMerchants
        .map((v) => v && v.markers_ && v.markers_[0].merchantData)
        .slice((currentPage - 1) * 9, currentPage * 9)
    );
  }, [currentPage, filter, visibleMerchants]);

  useEffect(() => {
    setCurrentPage(1);
  }, [filter, visibleMerchants]);

  useEffect(() => {
    if (!loading && currentMerchants) {
      getPlacesData(currentMerchants);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMerchants, visibleMerchants]);

  if (!currentMerchants) {
    return loading && <CircularProgress color="secondary" />;
  }

  const getNoResultsCopy = (filter) => {
    if (filter === "all") {
      return `${t__(theme.lang, common, "recommendedPlaces")} `;
    } else if (filter === "attractions" || filter === "top_attractions") {
      return `${t__(theme.lang, common, "recommended")} ${t__(
        theme.lang,
        common,
        "categories.attractions"
      )} `;
    } else if (filter === "transportation") {
      return `${t__(theme.lang, common, "recommended")} ${t__(
        theme.lang,
        common,
        "categories.transportation"
      )}`;
    } else {
      return `${t__(theme.lang, common, "recommendedPlacesTo")} ${t__(
        theme.lang,
        common,
        `categories.${CATEGORIES.find(
          (c) => c.mcc === filter
        ).title.toLowerCase()}`
      )} `;
    }
  };

  if (currentMerchants.length === 0) {
    return (
      <Typography>
        {t__(theme.lang, common, "looksLike")} {getNoResultsCopy(filter)}
        {t__(theme.lang, common, "inThisArea")}
      </Typography>
    );
  }

  if (currentMerchants) {
    return (
      <Root>
        <Grid
          container
          spacing={6}
          style={{
            paddingTop: theme.spacing(5),
            marginBottom: theme.spacing(10),
          }}
        >
          {currentMerchants.map((m, ind) => {
            if (m !== undefined) {
              return (
                <Grid xs={12} md={4} item style={{ display: "flex" }} key={ind}>
                  <Card className={classes.card} elevation={0}>
                    <CardActionArea
                      onClick={() => {
                        setDrawer(m);
                      }}
                      style={{ height: "100%" }}
                    >
                      <Box
                        width={smScreen ? "100%" : "auto"}
                        height="100%"
                        display="flex"
                        justifyContent="space-between"
                        flexDirection={smScreen ? "row" : "column"}
                      >
                        {googleData[m.google_place_id] &&
                        googleData[m.google_place_id].photos ? (
                          <CardMedia className={classes.media}>
                            <img
                              src={googleData[
                                m.google_place_id
                              ].photos[0].getUrl()}
                              alt=""
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = `/PlaceholderImages/${CATEGORIES.find(
                                  (c) => c.mcc === m.mcc
                                )?.title.toLowerCase()}/photo${Math.floor(
                                  Math.random() * 3 + 1
                                )}.jpg`;
                              }}
                            />
                          </CardMedia>
                        ) : (
                          <>
                            <CardMedia
                              className={classes.media}
                              image={`/PlaceholderImages/${CATEGORIES.find(
                                (c) => c.mcc === m.mcc
                              )?.title.toLowerCase()}/photo${Math.floor(
                                Math.random() * 3 + 1
                              )}.jpg`}
                            />
                            <Box
                              className={classes.placeholder}
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                            >
                              {CATEGORIES.find((c) => c.mcc === m.mcc)?.icon}
                            </Box>
                          </>
                        )}

                        <CardContent
                          style={{
                            padding: theme.spacing(3),
                            paddingBottom: 0,
                            width: smScreen ? "100%" : "auto",
                            display: smScreen ? "flex" : "block",
                            flexDirection: "column",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box>
                            <Box paddingBottom={1}>
                              <Typography variant="body2" color="secondary">
                                <b>
                                  {theme.lang === "en"
                                    ? m.name
                                    : googleData[m.google_place_id]?.name ??
                                      m.name}
                                </b>
                              </Typography>
                            </Box>
                            {googleData[m.google_place_id] &&
                              googleData[m.google_place_id].address && (
                                <Typography
                                  color="textSecondary"
                                  component="span"
                                  variant="h5"
                                >
                                  {googleData[m.google_place_id].address}
                                </Typography>
                              )}
                            <Box
                              display="flex"
                              color="text.secondary"
                              alignItems="center"
                              marginTop={1}
                            >
                              <span>
                                {CATEGORIES.find((c) => c.mcc === m.mcc)?.icon}
                              </span>
                              {googleData[m.google_place_id]?.price_level && (
                                <Typography
                                  component="span"
                                  color="textSecondary"
                                  variant="h5"
                                  style={{
                                    paddingLeft: theme.spacing(3),
                                  }}
                                >
                                  {[
                                    ...Array(
                                      googleData[m.google_place_id].price_level
                                    ),
                                  ].map(() => "$")}
                                  <span
                                    style={{
                                      position: "relative",
                                      left: "6px",
                                    }}
                                  >
                                    &bull;
                                  </span>
                                </Typography>
                              )}

                              <Typography
                                component="span"
                                color="textSecondary"
                                variant="h5"
                                style={{
                                  paddingLeft: theme.spacing(3),
                                }}
                              >
                                {t__(
                                  theme.lang,
                                  common,
                                  `categories.${CATEGORIES.find(
                                    (c) => c.mcc === m.mcc
                                  )?.title.toLowerCase()}`
                                )}
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            className={classes.details}
                            marginTop={3}
                            width="100%"
                          >
                            {smScreen &&
                              googleData[m.google_place_id] &&
                              googleData[m.google_place_id].rating && (
                                <Ratings
                                  data={googleData[m.google_place_id]}
                                  paddingY={theme.spacing(2)}
                                />
                              )}
                          </Box>
                        </CardContent>
                        {!smScreen && (
                          <Box minHeight={theme.spacing(7)}>
                            {googleData[m.google_place_id] &&
                              googleData[m.google_place_id].rating && (
                                <Ratings
                                  data={googleData[m.google_place_id]}
                                  paddingX={theme.spacing(3)}
                                  paddingBottom={theme.spacing(3)}
                                />
                              )}
                          </Box>
                        )}
                      </Box>
                    </CardActionArea>
                  </Card>
                </Grid>
              );
            } else {
              return null;
            }
          })}
        </Grid>
        {visibleMerchants.length / 9 > 1 && (
          <StyledPagination
            page={currentPage}
            count={Math.floor(visibleMerchants.length / 9) + 1}
            onChange={updatePagination}
            className={classes.pagination}
            siblingCount={0}
          />
        )}
      </Root>
    );
  }
};

MerchantsTable.propTypes = {
  map: PropTypes.object.isRequired,
  setDrawer: PropTypes.func.isRequired,
  visibleMerchants: PropTypes.array,
};

export default MerchantsTable;
