const common = {
  en: {
    siteTitle: "Discover Global Network Travel Guides",
    close: "Close",
    closeModal: "Close Modal",
    searchDefault: "Find a Destination",
    travelGuides: "Travel Guides",
    categories: {
      all: "All",
      eat: "Eat",
      shop: "Shop",
      stay: "Stay",
      attractions: "Attractions",
      transportation: "Transportation",
    },
    mustSeeAttractions: "Must-see attractions all over town",
    acceptanceLine:
      "Use your card here and anywhere you see these in-network logos:",
    recentGoogleReviews: "Recent Google Reviews",
    seeGoogleReviews: "See XX Google Reviews",
    trendingDestinations: "Trending Destinations",
    yourResults: "Your Results",
    noResults: "No results for ",
    noResultsCopy:
      "Only cities with Travel Guides and countries appear here. To find recommended merchants, check out a Travel Guide.",
    countries: "Destination Information",
    globalAcceptance: "Global Acceptance",
    guide: "Guide",
    techDiff: "Looks like something went wrong.",
    map: "Map",
    list: "List",
    acceptanceMap: "Acceptance Map",
    read: "Read",
    less: "less",
    more: "more",
    looksLike: "Looks like we don't have any",
    inThisArea: " in this area. Try moving the map to find more.",
    recommendedPlaces: "recommended places",
    recommendedPlacesTo: "recommended places to",
    recommended: "recommended",
    hideMe: "Hide Me",
    showMe: "Show Me",
    okay: "Okay",
    letsGo: "Let's Go",
    noThanks: "No Thanks",
    showingAllRecommended: "Showing all recommended places",
    showingRecommendedPlacesTo: "Showing recommended places to",
    showingRecommended: "Showing recommended",
    acceptanceIn: "Acceptance In",
    exploreTheGlobalMap: "Explore the Global Map",
    search: "Search",
    techDiffMessage:
      "We ran into some technical difficulties processing your request. Please try again later.",
    openNow: "Open Now",
    back: "Back",
  },
  es: {
    siteTitle: "Descubra las guías de viaje de Discover Global Network",
    close: "Cerrar",
    closeModal: "Cerrar Modal",
    searchDefault: "Buscar un Destino",
    travelGuides: "Guías de Viaje",
    categories: {
      all: "Todos",
      eat: "Comer",
      shop: "Comprar",
      stay: "Alojarse",
      attractions: "Atracciones",
      transportation: "Transporte",
    },
    mustSeeAttractions: "Atracciones imperdibles en toda la ciudad",
    acceptanceLine:
      "Use su tarjeta aquí y en cualquier lugar donde vea estos logotipos dentro de la red:",
    recentGoogleReviews: "Revisiones recientes de Google",
    seeGoogleReviews: "Ver XX reseñas de Google",
    trendingDestinations: "Destinos de Tendencia",
    yourResults: "Sus Resultados",
    noResults: "No hay resultados para",
    noResultsCopy:
      "Aquí solamente aparecen ciudades con guías de viaje y países. Para encontrar comercios recomendados, consulte una Guía de Viajes.",
    countries: "Información de destino",
    globalAcceptance: "Aceptación global",
    guide: "Guía",
    techDiff: "Parece que algo salió mal.",
    map: "Mapa",
    list: "Lista",
    acceptanceMap: "Mapa de aceptación",
    read: "Leer",
    less: "menos",
    more: "mas",
    looksLike: "Parece que no tenemos ningún punto",
    inThisArea: "en esta área. Intente mover el mapa para encontrar más.",
    recommendedPlaces: "lugares recomendados",
    recommendedPlacesTo: "lugares recomendados para",
    recommended: "recomendado",
    hideMe: "Ocultarme",
    showMe: "Mostrandome",
    okay: "Bien",
    letsGo: "Vamos",
    noThanks: "No, gracias",
    showingAllRecommended: "Mostrando todos los lugares recomendados",
    showingRecommendedPlacesTo: "Mostrando lugares recomendados para",
    showingRecommended: "Mostrando recomendado",
    acceptanceIn: "Aceptación en",
    exploreTheGlobalMap: "Explore el mapa global",
    search: "Buscar",
    techDiffMessage:
      "Tuvimos algunas dificultades técnicas al procesar su solicitud. Inténtelo de nuevo más tarde.",
    openNow: "Abierto ahora",
    back: "Anterior",
  },
};

export default common;
