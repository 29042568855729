const footer = {
  homeLink: "https://www.discoverglobalnetwork.com/",
  commonLinks: [
    {
      title: "Privacy",
      href: "https://www.discoverglobalnetwork.com/privacy-policy/",
    },
    {
      title: "Terms of Use",
      href: "https://www.discoverglobalnetwork.com/terms-of-use/",
    },
    {
      title: "AdChoices",
      href: "https://optout.aboutads.info/?c=2&lang=EN?",
    },
  ],
  socialMedia: {
    twitter: "https://twitter.com/DiscoverGlobal",
    facebook: "https://www.facebook.com/discoverglobalnetwork",
    youtube: "https://www.youtube.com/user/DiscoverNetworkUS",
    linkedIn: "https://www.linkedin.com/company/discover-global-network/",
  },
};

export default { footer };
