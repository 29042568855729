import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Link,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Typography,
  useTheme,
} from "@mui/material/";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Skeleton from "@mui/material/Skeleton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import PhoneIcon from "@mui/icons-material/Phone";
import LanguageIcon from "@mui/icons-material/Language";
import t__ from "../../../util/lang/util";
import common from "../../../util/lang/common";

const PREFIX = "MerchantHours";

const classes = {
  hours: `${PREFIX}-hours`,
  contactList: `${PREFIX}-contactList`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.hours}`]: {
    boxShadow: "none",
    borderRadius: 0,
    paddingLeft: 0,
    "& p": {
      fontSize: ".8rem !important",
    },
    "&.Mui-expanded": {
      margin: 0,
    },

    "& .MuiAccordionSummary-root": {
      minHeight: "1px",
      paddingLeft: 0,
    },
    "& .MuiList-padding": {
      padding: 0,
    },
    "& .MuiAccordionDetails-root": {
      padding: 0,
    },
    "& .MuiListItem-gutters": {
      padding: "0",
      fontSize: ".8rem",
    },

    "& .MuiAccordionSummary-content": {
      margin: 0,
    },
    "& .MuiAccordionSummary-expandIcon": {
      minHeight: "1px",
      margin: 0,
      padding: 0,
    },

    "&:before": {
      backgroundColor: "#fff",
    },
  },

  [`& .${classes.contactList}`]: {
    "& .MuiTypography-body1": {
      fontSize: "14px",
    },
    "& .MuiAvatar-root": {
      height: 42,
      width: 42,
      color: theme.palette.secondary.main,
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${theme.palette.secondary.main}`,
    },
    "& a": {
      display: "flex",
      alignItems: "center",
      color: theme.palette.text.primary,
    },
  },
}));

const MerchantHours = ({ drawer, googleData, loading }) => {
  const theme = useTheme();

  return (
    <StyledBox paddingX={5} paddingY={7}>
      {loading ? (
        <Skeleton height={theme.spacing(7)} />
      ) : (
        <>
          {!googleData[drawer.google_place_id]?.opening_hours ? null : (
            <Accordion className={classes.hours}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon color="secondary" size="small" />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="body2">
                  {googleData[
                    drawer.google_place_id
                  ].opening_hours.isOpen() && (
                    <Typography
                      style={{
                        color: theme.palette.success.main,
                        paddingRight: theme.spacing(1),
                      }}
                      component="span"
                      variant="body2"
                    >
                      <b>{t__(theme.lang, common, "openNow")}</b>{" "}
                      <span style={{ paddingRight: theme.spacing(1) }}>
                        &bull;
                      </span>
                    </Typography>
                  )}

                  {googleData[drawer.google_place_id].opening_hours.isOpen()
                    ? googleData[
                        drawer.google_place_id
                      ].opening_hours.weekday_text[new Date().getDay()].split(
                        ": "
                      )[1]
                    : googleData[drawer.google_place_id].opening_hours
                        .weekday_text[
                        new Date().getDay() === 0 ? 6 : new Date().getDay() - 1
                      ]}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  {googleData[
                    drawer.google_place_id
                  ].opening_hours.weekday_text.map((d, ind) => (
                    <ListItem key={`list${ind}`}>{d}</ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          )}
        </>
      )}

      <List className={classes.contactList} dense>
        {googleData[drawer.google_place_id] &&
          googleData[drawer.google_place_id].address && (
            <ListItem disableGutters>
              <Link
                href={
                  googleData[drawer.google_place_id]
                    ? googleData[drawer.google_place_id].url
                    : "#"
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <ListItemAvatar>
                  <Avatar>
                    <ArrowForwardIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={googleData[drawer.google_place_id].address}
                />
              </Link>
            </ListItem>
          )}
        {googleData[drawer.google_place_id] &&
          googleData[drawer.google_place_id].formatted_phone_number && (
            <ListItem disableGutters>
              <Link
                href={`tel:${googleData[
                  drawer.google_place_id
                ].formatted_phone_number.replace(/[^\d]/g, "")}`}
              >
                <ListItemAvatar>
                  <Avatar>
                    <PhoneIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    googleData[drawer.google_place_id].formatted_phone_number
                  }
                />
              </Link>
            </ListItem>
          )}
        {googleData[drawer.google_place_id] &&
          googleData[drawer.google_place_id].website && (
            <ListItem disableGutters>
              <Link
                href={googleData[drawer.google_place_id].website}
                target="_blank"
                rel="noopener noreferrer"
              >
                <ListItemAvatar>
                  <Avatar>
                    <LanguageIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  sx={{ color: theme.palette.secondary.main }}
                  primary={
                    <Box component="span" style={{ wordBreak: "break-word" }}>
                      {googleData[drawer.google_place_id].website}
                    </Box>
                  }
                />
              </Link>
            </ListItem>
          )}
      </List>
    </StyledBox>
  );
};

MerchantHours.propTypes = {
  drawer: PropTypes.object,
  googleData: PropTypes.object,
  loading: PropTypes.bool.isRequired,
};

export default MerchantHours;
