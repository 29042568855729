const trackLocationChange = () => {
  if (!window.s) return;
  if (document.cookie.includes("_evidon_consent_cookie")) {
    window.s.pageName = window.s.getPageName();
    window.s.prop22 = window.location.href;
    window.s.t();
  }
};

export default trackLocationChange;
