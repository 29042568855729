import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material/";
import Container from "../../Layout/Container";
import t__ from "../../../util/lang/util";
import cityViewCopy from "../../../util/lang/cityViewCopy";
import common from "../../../util/lang/common";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import isDefaultBrand from "../../../util/isDefaultBrand";

const PREFIX = "CityHeader";

const classes = {
  backLink: `${PREFIX}-backLink`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.backLink}`]: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.common.white,
    "&.MuiLink-underlineHover:hover, &.MuiLink-underlineHover:focus": {
      color: theme.palette.common.white,
    },
  },
}));

//dynamic import all the city images
const context = require.context(
  "../../CityImages/marquee",
  false,
  /\.(png|jpe?g|svg)$/
);
const cityImages = {};
context.keys().forEach((key) => {
  const prefix = key
    .split("./")
    .pop() // remove the first 2 characters
    .substring(0, key.length - 6); // remove the file extension
  cityImages[prefix] = context(key);
});

const CityHeader = ({
  cityView,
  filteredMerchants,
  setFilteredMerchants,
  ...props
}) => {
  const theme = useTheme();
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return (
    <StyledBox
      width="100%"
      bgcolor={
        isDefaultBrand(theme)
          ? theme.palette.secondary.light
          : theme.palette.brand.primaryBg
      }
      color={
        isDefaultBrand(theme)
          ? "text.light"
          : mdUp
          ? "text.light"
          : theme.palette.brand.text
      }
      display="flex"
      alignItems="center"
      style={
        mdUp
          ? {
              height: "143px",
            }
          : { height: "100px" }
      }
    >
      <Container bgcolor="transparent" zIndex="3">
        {!mdUp ? (
          <Box position="absolute" top={30} left={0}>
            <Button
              href={
                isDefaultBrand(theme)
                  ? `/guides`
                  : `/guides/?brand=${theme.brand}`
              }
            >
              <ArrowBackIosIcon
                style={{
                  color: `${
                    isDefaultBrand(theme)
                      ? theme.palette.common.white
                      : theme.palette.brand.text
                  }`,
                }}
              />
            </Button>
          </Box>
        ) : (
          <Box
            position="absolute"
            top={62}
            left={30}
            color={theme.palette.common.white}
          >
            <Link
              href={
                isDefaultBrand(theme)
                  ? `/guides`
                  : `/guides/?brand=${theme.brand}`
              }
              className={classes.backLink}
            >
              <ArrowBackIosIcon
                style={{
                  color: theme.palette.common.white,
                  fontSize: "1.2rem",
                }}
              />
              <Typography
                variant="h5"
                component="span"
                style={{ color: theme.palette.common.white }}
              >
                <b>{t__(theme.lang, common, "back")}</b>
              </Typography>
            </Link>
          </Box>
        )}
        <Typography
          variant={"h2_variant"}
          align="center"
          component="h1"
          sx={{
            padding: {
              xs: "0 30px",
              sm: "0 40px",
              md: "0 80px",
              lg: "0 80px",
            },
          }}
        >
          <b>
            {t__(
              theme.lang,
              cityViewCopy,
              `${cityView.replace(" ", "").toLowerCase()}.${
                mdUp ? "marqueeHeadline" : "mobileHeadline"
              }`
            )}
          </b>
        </Typography>
      </Container>
      {/*mdUp && (
        <Box
          position="absolute"
          height="100%"
          width="143px"
          right={{ xs: "-72px", md: "0" }}
          zIndex="2"
        >
          <svg width="100%" height="100%">
            <defs>
              <mask id="svg-circle-mask">
                <rect x="0px" y="0px" width="100%" height="100%" fill="white" />
                <circle
                  id="svg-circle"
                  cx="0px"
                  cy="50%"
                  r="71.5"
                  fill="black"
                />
              </mask>
              <linearGradient
                x1="20.259%"
                y1="12.586%"
                x2="90.952%"
                y2="80.756%"
                id="a"
              >
                <stop stopColor="#EC6B29" offset="0%" />
                <stop stopColor="#FCB116" offset="100%" />
              </linearGradient>
            </defs>
            <rect
              x="0"
              y="0"
              width="100%"
              height="100%"
              fill={
                isDefaultBrand(theme)
                  ? "#313c97"
                  : theme.palette.brand.introMarqueeBg
              }
              mask="url(#svg-circle-mask)"
            ></rect>
            {isDefaultBrand(theme) && (
              <circle
                cx="100%"
                cy="50%"
                r="67.5"
                transform="translate(0 -.001)"
                stroke="url(#a)"
                strokeWidth="8"
                fill="none"
                fillRule="evenodd"
              />
            )}
            {!isDefaultBrand(theme) &&
              theme.palette.brand.lightHeaderCircle && (
                <circle
                  cx="100%"
                  cy="50%"
                  r="71"
                  transform="translate(0 -.001)"
                  stroke="url(#a)"
                  strokeWidth="0"
                  fill={theme.palette.brand.lightHeaderCircle}
                  fillRule="evenodd"
                />
              )}
          </svg>
        </Box>
      )*/}
    </StyledBox>
  );
};

CityHeader.propTypes = {
  cityView: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  filteredMerchants: PropTypes.array,
  setFilteredMerchants: PropTypes.func,
  props: PropTypes.object,
};

export default CityHeader;
