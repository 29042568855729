export default {
  MuiDialog: {
    paper: {
      width: "100vw",
    },
  },
  MuiDialogTitle: {
    root: {
      display: "flex",
    },
  },
  MuiDialogContent: {
    root: {
      "@media (min-width: 600px)": {
        padding: "0 50px 50px 50px",
      },
      "&:first-child": {
        "@media (min-width: 600px)": {
          paddingTop: "50px",
        },
      },
    },
  },
};
