// https://material-ui.com/customization/theming/
// https://material-ui.com/customization/default-theme/
import { createTheme } from "@mui/material/styles";
import breakpoints from "../default/breakpoints";
import palette from "./palette";
import spacing from "../default/spacing";
import typography from "../default/typography";
import footer from "./footer";
import overrides from "../default/overrides";

// Create a theme instance.
const elo = createTheme({
  ...breakpoints,
  ...palette,
  ...spacing,
  ...typography,
  ...footer,
  ...overrides,
});
//set language to spanish
elo.lang = "en";
elo.brand = "troy";

export default elo;
