// https://material-ui.com/customization/theming/
// https://material-ui.com/customization/default-theme/
import { createTheme } from "@mui/material/styles";
import breakpoints from "./breakpoints";
import palette from "./palette";
import spacing from "./spacing";
import typography from "./typography";
import overrides from "./overrides";
import footer from "./footer";

// Create a theme instance.
const theme = createTheme({
  ...breakpoints,
  ...palette,
  ...spacing,
  ...typography,
  ...footer,
  components: overrides,
});
// Set language
theme.lang = "en";
// Set MaxWidth for content that's not full bleed
theme.containerMaxWidth = 1200;

export default theme;
