import baseline from "./MuicssBaseline";
import container from "./MuiContainer";
import typography from "./MuiTypography";
import listItem from "./MuiListItem";
import select from "./MuiSelect";
import checkbox from "./MuiCheckbox";
import menu from "./MuiMenu";
import dialog from "./MuiDialog";
import link from "./MuiLink";
import button from "./MuiButton";

// https://material-ui.com/customization/globals/
// https://material-ui.com/customization/components/

export default {
  ...link,
  ...baseline,
  ...container,
  ...typography,
  ...listItem,
  ...select,
  ...checkbox,
  ...menu,
  ...dialog,
  ...link,
  ...button,
};
