import { createContext, useState, useRef } from "react";
import { useGoogleMapsApi } from "./hooks/useGoogleMapsApi";
import { GOOGLE_MAPS_API_KEY } from "./util/keys";
import useCallbackRef from "./hooks/useRefCallback";

const GOOGLE_MAPS_LIBRARIES = ["places"];

const MapContext = createContext({});

const MapContextProvider = ({ children, props }) => {
  // Start loading google api as early as possible.
  const [google, googleLoadError] = useGoogleMapsApi(
    GOOGLE_MAPS_API_KEY,
    GOOGLE_MAPS_LIBRARIES
  );

  // Map center is set by default to middle of Atlantic
  const [mapCenter, setMapCenter] = useState({
    lat: window.innerWidth < 1200 ? 20 : 30,
    lng: window.innerWidth < 1200 ? -100 : 11,
  });

  const [cityView, setCityView] = useState(false);
  const [mapContainerRef, mapContainerCallbackRef] = useCallbackRef();

  //store a "sanitized and translated" set of cities data once we get it from the api.
  const [cities, setCities] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [acceptanceData, setAcceptanceData] = useState(null);
  const [merchants, setMerchants] = useState(null);
  const [filter, setFilter] = useState(null);
  const [mapLoading, setMapLoading] = useState(true);
  const [googleData, setGoogleData] = useState({});
  const [listView, setListView] = useState(false);
  const [drawer, setDrawer] = useState(null);
  const [searchDrawer, setSearchDrawer] = useState(false);
  const [locationShared, setLocationShared] = useState([]);
  const activeTooltip = useRef();

  return (
    <MapContext.Provider
      value={{
        google,
        googleLoadError,
        mapContainerRef,
        mapContainerCallbackRef,
        mapCenter,
        setMapCenter,
        cityView,
        setCityView,
        cities,
        setCities,
        selectedCountry,
        setSelectedCountry,
        acceptanceData,
        setAcceptanceData,
        merchants,
        setMerchants,
        filter,
        setFilter,
        mapLoading,
        setMapLoading,
        googleData,
        setGoogleData,
        listView,
        setListView,
        drawer,
        setDrawer,
        searchDrawer,
        setSearchDrawer,
        locationShared,
        setLocationShared,
        activeTooltip,
      }}
    >
      {children}
    </MapContext.Provider>
  );
};

export { MapContext, MapContextProvider };
