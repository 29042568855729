import { getCityGuideData } from "/";
const { useQuery } = require("react-query");

//type: ["cities", "merchants", "categories"]
function useCityGuideData(type, params) {
  return useQuery(
    [type, params],
    async () => {
      if (type === "merchants" && !params.merchant_city) {
        return;
      }
      const response = await getCityGuideData(type, params);
      if (response.ok) {
        return await response.json();
      } else {
        throw new Error(response.status);
      }
    },
    { refetchOnWindowFocus: false } // this data doesnt update often
  );
}

export default useCityGuideData;
