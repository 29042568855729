import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { MapContext } from "../../contexts";
import {
  Box,
  Grid,
  Typography,
  useTheme,
  Button,
  useMediaQuery,
} from "@mui/material/";
import TravelGuidesLogo from "../CountryView/TravelGuidesLogo";
import Container from "../Layout/Container";
import CATEGORIES from "../../util/categories";
import AtmBox from "../AtmBox";
import AcceptanceMarks from "../AcceptanceMarks";
import CountryCallout from "./CountryCallout";
import t__ from "../../util/lang/util";
import cityViewCopy from "../../util/lang/cityViewCopy";
import caribbean from "../../util/lang/caribbean";
import common from "../../util/lang/common";
import TopAttractionsCarousel from "./TopAttractionsCarousel";
import MerchantsTable from "./MerchantsTable";
import MerchantDrawer from "./MerchantDrawer";
import MerchantSpotlight from "./MerchantSpotlight";
import DN from "../Icons/discover-card@3x.webp";
import DCI from "../Icons/diners-club-international-card@3x.webp";
import ELO from "../Icons/elo-card@3x.webp";
import RuPay from "../Icons/rupay-card@3x.webp";
import Troy from "../Icons/troy-card@3x.webp";
import DinaCard from "../Icons/dinacard-card@3x.webp";
import cabal from "../Icons/verve-card@3x.webp";
import BC from "../Icons/bc-card@3x.webp";
import Mercury from "../Icons/mercury.png";
import isDefaultBrand from "../../util/isDefaultBrand";

const cards = [
  {
    img: DN,
    card: "Discover®",
  },
  {
    img: DCI,
    card: "Diners Club International®",
  },
  {
    img: Troy,
    card: "Troy",
  },
  {
    img: cabal,
    card: "Cabal",
  },
  {
    img: RuPay,
    card: "RuPay",
  },
  {
    img: BC,
    card: "BC Card",
  },
  {
    img: DinaCard,
    card: "DinaCard",
  },
  {
    img: ELO,
    card: "Elo",
  },
  {
    img: Mercury,
    card: "Mercury",
  },
];

const CityView = ({
  activePin,
  cityView,
  filteredMerchants,
  map,
  setActivePin,
  setFilteredMerchants,
  setMerchantTooltip,
  visibleMerchants,
  ...props
}) => {
  const { acceptanceData, cities, filter, merchants, drawer, setDrawer } =
    useContext(MapContext);
  const [countryData, setCountryData] = useState(null);
  const theme = useTheme();
  const isUSA = countryData?.cca3 === "USA";
  const mdScreen = useMediaQuery(theme.breakpoints.up("md"));
  const lgScreen = useMediaQuery(theme.breakpoints.up("lg"));

  useEffect(() => {
    if (!acceptanceData) return;
    const data = acceptanceData.find(
      (a) => a.cca3 === cities.find((c) => c.city === cityView).country
    );
    setCountryData(data);
  }, [acceptanceData, cities, cityView]);

  if (!countryData) return "";

  if (filter) {
    return (
      <Container pt={10} pb={visibleMerchants.length === 0 ? 10 : 0}>
        {visibleMerchants.length > 0 && filteredMerchants && (
          <Typography>
            <>
              {filter === "all" ? (
                t__(theme.lang, common, "showingAllRecommendedPlaces")
              ) : (
                <>
                  {["restaurants", "retail", "hotels"].includes(filter) ? (
                    <>
                      {t__(theme.lang, common, "showingRecommendedPlacesTo")}{" "}
                      {t__(
                        theme.lang,
                        common,

                        `categories.${CATEGORIES.find(
                          (c) => c.mcc === filter
                        ).title.toLowerCase()}`
                      )}{" "}
                      {t__(theme.lang, common, "here")}
                    </>
                  ) : (
                    <>
                      {t__(theme.lang, common, "showingRecommended")}{" "}
                      {t__(
                        theme.lang,
                        common,

                        `categories.${CATEGORIES.find(
                          (c) => c.mcc === filter
                        ).title.toLowerCase()}`
                      )}{" "}
                      {t__(theme.lang, common, "here")}
                    </>
                  )}
                </>
              )}
            </>
          </Typography>
        )}
        {visibleMerchants && (
          <MerchantsTable
            visibleMerchants={visibleMerchants}
            map={map}
            drawer={drawer}
            setDrawer={setDrawer}
          />
        )}
        {drawer && (
          <MerchantDrawer
            activePin={activePin}
            drawer={drawer}
            map={map}
            setDrawer={setDrawer}
            setActivePin={setActivePin}
            marks={countryData.credit_mark}
          />
        )}
      </Container>
    );
  } else {
    return (
      <>
        <Container pt={{ xs: 4, md: 6 }}>
          <TravelGuidesLogo />
          <Box marginTop={3} marginBottom={3}>
            <Typography variant="h2">
              <b>
                {t__(
                  theme.lang,
                  cityViewCopy,
                  `${cityView
                    .replace(" ", "")
                    .toLowerCase()}.travelGuideHeadline`
                )}
              </b>
            </Typography>
          </Box>
          <Typography>
            {t__(
              theme.lang,
              cityViewCopy,
              `${cityView.replace(" ", "").toLowerCase()}.travelGuideBody`
            )}
          </Typography>
          <Box
            borderRadius={theme.spacing(3)}
            border={1}
            padding={5}
            marginTop={5}
            marginBottom={10}
            sx={{
              borderColor: `${theme.palette.borders.light} !important`,
            }}
          >
            <Typography>
              {t__(theme.lang, cityViewCopy, "acceptedPaymentsIn")}
            </Typography>
            <AcceptanceMarks
              marks={countryData.credit_mark}
              variant="small"
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(8),
              }}
            />

            <Typography>
              {t__(theme.lang, cityViewCopy, "dgnNetwork")}
            </Typography>
            <ul
              style={{
                display: "flex",
                flexWrap: "wrap",
                listStyle: "none",
                margin: 0,
                padding: 0,
                marginTop: theme.spacing(3),
              }}
            >
              {cards.map((c) => (
                <li style={{ margin: "0 6px 0 0", padding: 0, width: 100 }}>
                  <img src={c.img} alt={c.card} width="100%" />
                  <Typography
                    variant="h5"
                    sx={{ fontWeight: 700, paddingLeft: 2 }}
                  >
                    {c.card}
                  </Typography>
                </li>
              ))}
            </ul>
          </Box>
        </Container>
        {/*until we know what we are doing here keeping this component a one-off*/}
        {cityView === "Barcelona" && <MerchantSpotlight />}
        <Container>
          <Box marginTop={7}>
            <Typography variant="h2">
              <b>{t__(theme.lang, common, "mustSeeAttractions")}</b>
              <br />
            </Typography>
          </Box>
          {merchants && (
            <Box marginRight={theme.spacing(-2.5)} marginTop={7}>
              <TopAttractionsCarousel
                attractions={[...merchants].filter(
                  (m) => m.mcc === "top_attractions"
                )}
                map={map}
              />
            </Box>
          )}

          <Box mt={10} mb={10}>
            <Grid container spacing={6}>
              <Grid xs={12} md={6} item style={{ display: "flex" }}>
                <CountryCallout
                  countryData={countryData}
                  setFilteredMerchants={setFilteredMerchants}
                />
              </Grid>
              <Grid xs={12} md={6} item style={{ display: "flex" }}>
                <AtmBox countryData={countryData} />
              </Grid>
            </Grid>
          </Box>
          {isUSA && (
            <Box mb={10}>
              <Typography variant="body2" color="textSecondary">
                *{t__(theme.lang, cityViewCopy, "nilson")}
              </Typography>
            </Box>
          )}
          {cityView === "San Juan" && (
            <Box
              bgcolor="background.light"
              width="100%"
              borderRadius={3}
              mb={10}
              overflow="hidden"
            >
              <Box display={{ xs: "block", md: "flex" }} width="100%">
                <Box
                  padding={{ xs: 5, md: 10, lg: 0 }}
                  display={{ xs: "block", md: "flex" }}
                  flexDirection={{ md: "column", lg: "row" }}
                  width={{ xs: "100%", md: "65%", lg: "auto" }}
                  flexShrink={{ xs: "unset", md: 0, lg: "unset" }}
                  flexGrow={{ lg: 1 }}
                  alignItems={{ lg: "center" }}
                  justifyContent={{ md: "center", lg: "start" }}
                >
                  <Box
                    maxWidth={{ md: "350px", lg: "400px", xl: "550px" }}
                    paddingX={{ lg: 10 }}
                  >
                    <Typography
                      variant="h3"
                      fontSize={{ xs: "16px", md: "20px" }}
                      lineHeight={1.4}
                    >
                      <b>{t__(theme.lang, caribbean, "islandHopping")}</b>
                    </Typography>
                  </Box>

                  <Box>
                    <Button
                      sx={{
                        fontSize: "19px",
                        fontWeight: "bold",
                        color: `${theme.palette.common.white} !important`,
                        textTransform: "none",
                        height: 44,
                        padding: "0 24px",
                        borderRadius: 20,
                        marginTop: { xs: 6, lg: 0 },
                        whiteSpace: "nowrap",
                        display: { xs: "inline-flex" },
                        backgroundColor: `${
                          isDefaultBrand(theme)
                            ? theme.palette.primary.light
                            : theme.palette.primary.main
                        }`,
                        "&:hover, &:focus": {
                          backgroundColor: `${
                            isDefaultBrand(theme)
                              ? "#E94D00"
                              : theme.palette.brand.primaryBg
                          }`,
                        },
                      }}
                      href={`/guides/caribbean/${
                        !isDefaultBrand(theme) ? `?brand=${theme.brand}` : ``
                      }`}
                    >
                      {t__(theme.lang, caribbean, `seeMoreIslands`)}
                    </Button>
                  </Box>
                </Box>
                <Box
                  alignSelf="flex-end"
                  fontSize={0}
                  mt={{ xs: 2, md: 0 }}
                  position="relative"
                >
                  <Box
                    bgcolor="primary.light"
                    height="137px"
                    fontSize={0}
                    width="137px"
                    borderRadius="137px"
                    position="absolute"
                    top="-68px"
                    right={{ xs: "-68px", md: "auto" }}
                    left={{ md: "-68px" }}
                    sx={{
                      background:
                        "linear-gradient(180deg, rgba(227,89,32,1) 0%, rgba(240,128,32,1) 69%, rgba(249,155,32,1) 100%)",
                    }}
                  />
                  <img
                    src="/caribbean_marquee_hero.jpg"
                    alt="Man peering into a camera on the beach"
                    style={{
                      height: mdScreen
                        ? lgScreen
                          ? "165px"
                          : "250px"
                        : "150px",
                      width: mdScreen ? (lgScreen ? "auto" : "100%") : "100%",
                      objectFit: "cover",
                    }}
                  />
                </Box>
              </Box>
            </Box>
          )}
        </Container>

        {drawer && (
          <MerchantDrawer
            activePin={activePin}
            drawer={drawer}
            map={map}
            setDrawer={setDrawer}
            setActivePin={setActivePin}
            marks={countryData.credit_mark}
          />
        )}
      </>
    );
  }
};

CityView.propTypes = {
  cityView: PropTypes.string,
  filteredMerchants: PropTypes.array,
  map: PropTypes.object.isRequired,
  props: PropTypes.object,
  setMerchantTooltip: PropTypes.func,
  visibleMerchants: PropTypes.array,
};

export default CityView;
