/* eslint-disable import/no-anonymous-default-export */
// https://material-ui.com/customization/palette/

/**
 * interface PaletteColor {
 *  light?: string;
 *  main: string;
 *  dark?: string;
 *  contrastText?: string;
 * }
 */
export const palette = {
  primary: {
    main: "#23233f", //midnight blue
    light: "#eb6d29", //discover orange
  },
  secondary: {
    main: "#3057b9", //royal blue
    light: "#313c97", //medium blue
  },
  success: {
    main: "#39834d", //green for GO!
  },
  error: {
    main: "#cd2026",
  },
  text: {
    primary: "#23233f", //midnight blue
    secondary: "#63686b", //medium gray
    light: "#ffffff",
  },
  background: {
    default: "#FFFFFF",
    light: "#f0f0f2",
    medLight: "#ededed",
    dark: "#e9e9e9",
    darkgray: "#19191b",
  },
  borders: {
    light: "#dbdbdb",
    medium: "#d4d3dc",
    dark: "#d8d7e6", //light periwinkle
  },
  input: {
    border: "#949494",
  },
  button: {
    inert: "#b9b9b9",
  },
};

export default {
  palette,
};
