const caribbean = {
  en: {
    siteTitle: "Travel the Caribbean with Discover",
    pageDescription:
      "Yes. Discover Card is accepted at popular tourist spots across the Caribbean islands at places like restaurants, hotels, stores, supermarkets, ATMs, and more.",
    headline: "Accepted in popular Caribbean destinations",
    featuredMerchants: {
      header: (
        <>
          Shop. Dine. Stay.
          <br />
          At popular Caribbean spots like these.
        </>
      ),
      merchants: [
        {
          category: "Dining",
          title: "Margaritaville Restaurant",
          city: "US Virgin Islands",
        },
        {
          category: "Attractions",
          title: "Dolphin Cove",
          city: "Jamaica",
        },
        {
          category: "Hotels",
          title: "Margaritaville Beach Resort",
          city: "Bahamas",
        },
        {
          category: "Attractions",
          title: "Toro Verde Zip Line",
          city: "Puerto Rico",
        },
        {
          category: "Dining",
          title: "Señor Frogs Restaurant",
          city: "Bahamas",
        },
        {
          category: "Transportation",
          title: "Budget Rent a Car ",
          city: "US Virgin Islands",
        },

        {
          category: "Hotels",
          title: "El Conquistador",
          city: "Puerto Rico",
        },
        {
          category: "Transportation",
          title: "National Alamo",
          city: "Dominican Republic",
        },
        {
          category: "Transportation",
          title: "Hertz Aeropuerto",
          city: "Puerto Rico",
        },
        {
          category: "Attractions",
          title: "Carabali Park",
          city: "Puerto Rico",
        },
        {
          category: "Hotels",
          title: "Secrets Resort and Spa",
          city: "Jamaica",
        },
        {
          category: "Attractions",
          title: "Coco Bongo Punta Cana",
          city: "Dominican Republic",
        },
      ],
    },
    exploreSanJuan: "Explore San Juan",
    confidently: "Confidently use your card across",
    islandHopping:
      "Island hopping? Take your Discover® Card and confidently use it across the Caribbean.",
    seeMoreIslands: "See More Islands",
    dontForget: "Don't forget to pack your",
    discoverCard: "Discover® Card",
    useEverywhere: "Use your card everywhere you see these logos",
    destinations: [
      {
        title: "Bahamas",
        description:
          "16 amazing islands and many places to use your card across them all.",
        drawerDescription:
          "Fly away to the world's clearest ocean, 16 islands, and Discover acceptance across its places to shop, dine, and stay.",
        img: "/destinations/Destinations_Bahamas.jpg",
        width: "32%",
      },
      {
        title: "Dominican Republic",
        description:
          "City vibes and secluded beaches, plus many places to use your card across them both.",
        drawerDescription:
          "The oldest country in the Americas offers city culture, beach vibes, and Discover acceptance across its many tourist spots.",
        img: "/destinations/Destinations_DominicanRepublic.jpg",
        width: "32%",
      },
      {
        title: "Jamaica",
        description:
          "Music, food, culture and many places to use your card across the island.",
        drawerDescription:
          "The birthplace of reggae offers music, food, natural beauty, and Discover acceptance across its culture of cool.",
        img: "/destinations/Destinations_Jamaica.jpg",
        width: "32%",
      },
      {
        title: "Puerto Rico",
        description:
          "Experience natural beauty and many places to use your card across the Island of Enchantment.",
        drawerDescription:
          "35 nature reserves, 100s of rivers, waterfalls, and Discover acceptance across its nature, nightlife, and more.",
        img: "/destinations/Destinations_PuertoRico.jpg",
        width: "49%",
      },
      {
        title: "US Virgin Islands / St. Thomas",
        description:
          "3 islands, an ocean, and a sea—plus many places to use your card across them all.",
        drawerDescription:
          "You don't need a passport to visit this US territory or have to worry about Discover acceptance across the three main islands.",
        img: "/destinations/Destinations_USVirginIslands.jpg",
        width: "49%",
      },
    ],
    acceptancecallOut: "See Where",
    acceptanceATMcoverage:
      "Because your card is part of the Discover Global Network, it is good to go.",
    noAcceptanceATMcoverage: {
      Europe:
        "Because you’re a part of the Discover Global Network, you can use your card across some of Europe's most popular destinations, including Spain, Germany, the UK, Italy, Portugal and France.",
      Africa:
        "Because you’re a part of the Discover Global Network, you can use your card in the UAE, Egypt, Morocco and South Africa. With destinations in the Middle East & Africa expanding rapidly, you can use your card in more and more places here.",
      Asia: "Because you’re a part of the Discover Global Network, you can use your card almost everywhere in Japan as well as top travel destinations across Asia and Pacific Islands, including India, China, Singapore, Australia and more.",
      America:
        "Because you’re a part of the Discover Global Network, you can use your card across Canada, Mexico and the U.S., as well as in popular South American travel spots like Peru, Uruguay, Argentina, Chile and more.",
      Antarctica:
        "Because you’re a part of the Discover Global Network, you can use your card across Canada, Mexico and the U.S., as well as in popular South American travel spots like Peru, Uruguay, Argentina, Chile and more.",
    },
    lookFor: "Look for these acceptance marks:",
  },
  es: {
    siteTitle: "Viaja por el Caribe con Discover",
    pageDescription:
      "Sí. La tarjeta Discover es aceptada en lugares turísticos populares en las islas del Caribe, como restaurantes, hoteles, tiendas, supermercados, cajeros automáticos y más.",
    headline: "Aceptada en destinos populares del Caribe",
    featuredMerchants: {
      header: (
        <>
          Compra. Come. Alójate.
          <br />
          En lugares populares del Caribe como estos.
        </>
      ),
      merchants: [
        {
          category: "Dining",
          title: "Restaurante Margaritaville",
          city: "Islas Vírgenes de los EE. UU.",
        },
        {
          category: "Attractions",
          title: "Cueva de los Delfines",
          city: "Jamaica",
        },
        {
          category: "Hotels",
          title: "Resort Playa Margaritaville",
          city: "Bahamas",
        },
        {
          category: "Attractions",
          title: "Tirolina Toro Verde",
          city: "Puerto Rico",
        },
        {
          category: "Dining",
          title: "Restaurante Señor Frog's",
          city: "Bahamas",
        },
        {
          category: "Transportation",
          title: "Budget Alquiler de Autos",
          city: "Islas Vírgenes de los EE. UU.",
        },

        {
          category: "Hotels",
          title: "El Conquistador",
          city: "Puerto Rico",
        },
        {
          category: "Transportation",
          title: "National Alamo",
          city: "República Dominicana",
        },
        {
          category: "Transportation",
          title: "Hertz Aeropuerto",
          city: "Puerto Rico",
        },
        {
          category: "Attractions",
          title: "Parque Carabali",
          city: "Puerto Rico",
        },
        {
          category: "Hotels",
          title: "Secrets Resort y Spa",
          city: "Jamaica",
        },
        {
          category: "Attractions",
          title: "Coco Bongo Punta Cana",
          city: "República Dominicana",
        },
      ],
    },
    exploreSanJuan: "Explora San Juan",
    confidently: "Usa tu tarjeta con confianza en",
    islandHopping:
      "¿Vas a hacer un recorrido por las islas? Lleva tu tarjeta Discover® y úsala con confianza en todo el Caribe.",
    seeMoreIslands: "Ver Más Islas",
    dontForget: "No olvides empacar tu",
    discoverCard: "tarjeta Discover®",
    useEverywhere: "Usa tu tarjeta en todos los lugares donde veas estos logos",
    destinations: [
      {
        title: "Bahamas",
        description:
          "16 islas increíbles y muchos lugares para usar tu tarjeta en todas ellas.",
        drawerDescription:
          "Vuela hacia el océano más claro del mundo, 16 islas, y la aceptación de Discover en sus lugares para comprar, comer y alojarse.",
        img: "/destinations/Destinations_Bahamas.jpg",
        width: "1/3",
      },
      {
        title: "Dominican Republic",
        description:
          "Vibras urbanas y playas apartadas, además de muchos lugares donde usar tu tarjeta en ambos.",
        drawerDescription:
          "El país más antiguo de las Américas ofrece cultura urbana, ambiente de playa y aceptación de Discover en muchos de sus lugares turísticos.",
        img: "/destinations/Destinations_DominicanRepublic.jpg",
        width: "32%",
      },
      {
        title: "Jamaica",
        description:
          "Música, comida, cultura y muchos lugares para usar tu tarjeta en toda la isla.",
        drawerDescription:
          "La cuna del reggae ofrece música, comida, belleza natural, y aceptación de Discover en su cultura de frescura.",
        img: "/destinations/Destinations_Jamaica.jpg",
        width: "32%",
      },
      {
        title: "Puerto Rico",
        description:
          "Experimenta la belleza natural y muchos lugares para usar tu tarjeta en la Isla del Encanto.",
        drawerDescription:
          "35 reservas naturales, cientos de ríos y cascadas, y aceptación de Discover en su naturaleza, vida nocturna y más.",
        img: "/destinations/Destinations_PuertoRico.jpg",
        width: "49%",
      },
      {
        title: "US Virgin Islands / St. Thomas",
        description:
          "3 islas, un océano y un mar, además de muchos lugares para usar tu tarjeta en todos ellos.",
        drawerDescription:
          "No necesitas pasaporte para visitar este territorio de EE. UU. ni preocuparte por la aceptación de Discover en las tres principales islas.",
        img: "/destinations/Destinations_USVirginIslands.jpg",
        width: "49%",
      },
    ],
    acceptancecallOut: "Ver Dónde",
    acceptanceATMcoverage:
      "Porque tu tarjeta es parte de la Red Global de Discover, está lista para usar.",
    noAcceptanceATMcoverage: {
      Europe:
        "Como eres parte de la Red Global Discover, puedes usar tu tarjeta en algunos de los destinos más populares de Europa, incluyendo España, Alemania, el Reino Unido, Italia, Portugal y Francia.",
      Africa:
        "Como eres parte de la Red Global Discover, puedes usar tu tarjeta en los Emiratos Árabes Unidos, Egipto, Marruecos y Sudáfrica. Con los destinos en Oriente Medio y África expandiéndose rápidamente, puedes usar tu tarjeta en cada vez más lugares aquí.",
      Asia: "Como eres parte de la Red Global Discover, puedes usar tu tarjeta casi en cualquier lugar de Japón, así como en los principales destinos de viaje en Asia y las Islas del Pacífico, incluyendo India, China, Singapur, Australia y más.",
      America:
        "Como eres parte de la Red Global Discover, puedes usar tu tarjeta en Canadá, México y los EE. UU., así como en destinos populares de América del Sur como Perú, Uruguay, Argentina, Chile y más.",
      Antarctica:
        "Como eres parte de la Red Global Discover, puedes usar tu tarjeta en Canadá, México y Estados Unidos, así como en destinos populares de América del Sur como Perú, Uruguay, Argentina, Chile y más.",
    },
    lookFor: "Busca estos símbolos de aceptación:",
  },
};

export default caribbean;
