import React, { useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { Box, Button, Link, Typography, useTheme } from "@mui/material/";
import Ratings from "../Ratings";
import Skeleton from "@mui/material/Skeleton";
import common from "../../../util/lang/common";
import t__ from "../../../util/lang/util";
import "../../../util/theme/slick.css";
import "../../../util/theme/slick-theme.css";
import Slider from "react-slick";

const PREFIX = "MerchantReviews";

const classes = {
  reviews: `${PREFIX}-reviews`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.reviews}`]: {
    marginRight: "-20px",
    "& a:hover": {
      textDecoration: "underline",
    },
    "& .slick-disabled": {
      display: "none !important",
    },
    "& .slick-track": {
      display: "flex !important",
      marginLeft: 0,
      marginRight: 0,
    },
    "& .slick-slide": {
      height: "auto",
      borderRadius: theme.spacing(3),
      border: `1px solid ${theme.palette.borders.light}`,
      marginRight: theme.spacing(5),
      padding: theme.spacing(5),
      boxShadow: "0 2px 6px 0 rgba(0, 0, 0, 0.25)",
    },
    "& .slick-slide > div": {
      display: "flex",
      flexDirection: "column",
      height: "100%",
    },
    "& .slick-list": {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      [theme.breakpoints.down("sm")]: {
        marginRight: theme.spacing(-2.5),
      },
    },
    "& .slick-dots": {
      position: "relative",
      bottom: "auto",
      marginTop: theme.spacing(2),
    },
    "& .slick-dots li button:before": {
      backgroundColor: theme.palette.background.dark,
    },
  },
}));

const MerchantReviews = ({
  drawer,
  expandedReviews,
  googleData,
  loading,
  setExpandedReviews,
}) => {
  const theme = useTheme();

  const ref = useRef([]);

  const [sliderLoaded, setSliderLoaded] = useState(false);

  return (
    <StyledBox paddingX={5} paddingY={7}>
      {loading ? (
        <Skeleton height={theme.spacing(25)} />
      ) : (
        <>
          {googleData[drawer.google_place_id]?.reviews &&
          googleData[drawer.google_place_id]?.reviews.find(
            (d) => d.rating >= 3
          ) ? (
            <>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="body2">
                  {t__(theme.lang, common, "recentGoogleReviews")}
                </Typography>
                <Ratings
                  data={googleData[drawer.google_place_id]}
                  hideOpenNow
                  textColor="secondary"
                  variant="body2"
                />
              </Box>

              <Slider
                arrows={false}
                dots={true}
                infinite={false}
                speed={500}
                slidesToShow={1.125}
                slidesToScroll={1}
                className={classes.reviews}
                onInit={() => setSliderLoaded(true)}
              >
                {googleData[drawer.google_place_id]?.reviews
                  .filter((d) => d.rating >= 3)
                  .map((d, i) => {
                    return (
                      <Box
                        flexDirection="column"
                        justifyContent="space-between"
                        height="100%"
                        mb={1}
                        key={d.author_name}
                      >
                        <Box>
                          <Box display="flex" justifyContent="space-between">
                            <Ratings
                              data={d}
                              size="small"
                              style={{ paddingRight: "20px" }}
                              hideOpenNow
                              hideNumber
                            />{" "}
                            <Typography
                              variant="h5"
                              component="span"
                              color="textSecondary"
                            >
                              {d.relative_time_description}
                            </Typography>
                          </Box>
                          <Box
                            maxHeight={!expandedReviews[i] ? 200 : 1000}
                            overflow={!expandedReviews[i] ? "hidden" : "auto"}
                            marginTop={3}
                            style={{
                              transition: "all 0.4s",
                            }}
                          >
                            <Typography
                              variant="h5"
                              component="p"
                              ref={(el) =>
                                (ref.current[i] = el && el.clientHeight)
                              }
                            >
                              {d.text}
                            </Typography>
                          </Box>

                          {sliderLoaded && ref.current[i] > 198 && (
                            <Box align="right">
                              <Button
                                onClick={() => {
                                  const newReviews = [...expandedReviews];
                                  newReviews[i] = !newReviews[i];
                                  setExpandedReviews(newReviews);
                                }}
                              >
                                <Typography variant="caption" color="secondary">
                                  {t__(theme.lang, common, "read")}{" "}
                                  {expandedReviews[i]
                                    ? t__(theme.lang, common, "less")
                                    : t__(theme.lang, common, "more")}
                                </Typography>
                              </Button>
                            </Box>
                          )}
                        </Box>
                        <Link href={d.author_url}>
                          <Box mt={4} display="flex" alignItems="center">
                            <img
                              src={d.profile_photo_url}
                              height="40"
                              alt=""
                              style={{ marginRight: "10px" }}
                            />
                            <Typography
                              variant="h5"
                              component="span"
                              sx={{ color: theme.palette.text.primary }}
                            >
                              {d.author_name}
                            </Typography>
                          </Box>
                        </Link>
                      </Box>
                    );
                  })}
              </Slider>
              <Link
                href={googleData[drawer.google_place_id].url}
                color="secondary"
                target="_blank"
              >
                <Typography variant="body2" align="center">
                  <b>
                    {t__(theme.lang, common, "seeGoogleReviews").replace(
                      "XX",
                      googleData[drawer.google_place_id].user_ratings_total
                    )}
                  </b>
                </Typography>
              </Link>
            </>
          ) : null}
        </>
      )}
    </StyledBox>
  );
};

MerchantReviews.propTypes = {
  drawer: PropTypes.object,
  expandedReviews: PropTypes.array,
  googleData: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  setExpandedReviews: PropTypes.func,
};

export default MerchantReviews;
